export function getEventPath (e: Event): Array<EventTarget> {
  // eslint-disable-next-line
  if ((e as any).path) {
    // eslint-disable-next-line
    return (e as any).path;
  }

  if (e.composedPath) {
    return e.composedPath();
  }

  const path = [];
  let el: HTMLElement | null = e.target as HTMLElement;

  while (el) {
    path.push(el);

    if (el.tagName === 'HTML') {
      path.push(document);
      path.push(window);

      return path;
    }

    el = el.parentElement;
  }

  return path;
}

export const listenOpts = {
  hasPassive: false,
  passiveCapture: true,
  notPassiveCapture: true
};

export function stopAndPrevent (e: Event): void {
  if (e.cancelable) {
    e.preventDefault();
  }
  e.stopPropagation();
}
