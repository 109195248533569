
import TheHeader from '@/components/layout/TheHeader.vue';
import TheBurgerMenu from '@/components/layout/TheBurgerMenu.vue';

export default {
  components: {
    TheBurgerMenu,
    TheHeader
  },

  head () {
    return {
      title: '«Страна Девелопмент» - Личный кабинет клиента',
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: '«Страна Девелопмент» - Личный кабинет клиента'
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: 'Личный кабинет клиента «Страна Девелопмент». Квартиры и коммерческие помещения в новостройках от застройщика.'
        },
        {
          hid: 'description',
          name: 'description',
          content: 'Личный кабинет клиента «Страна Девелопмент». Квартиры и коммерческие помещения в новостройках от застройщика.'
        }
      ]
    };
  }
};
