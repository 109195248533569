/*
  Эта функция нужна для того, чтобы синхронизировать последние
  просмотренные квартиры приходящие с портала кукой, в момент загрузки страниц кабинета
*/

import { defineNuxtPlugin } from '@/types/nuxt2CompositionApiShims';
import latestFavoritesSync from '@/mixins/latestFavoritesSync';

export default defineNuxtPlugin((context, inject) => {
  latestFavoritesSync(context);
});
