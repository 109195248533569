import {
  Context
} from '@nuxt/types';
import {
  Inject
} from '@nuxt/types/app';
import type {
  NuxtAxiosInstance
} from '@nuxtjs/axios';

import { EnvironmentType } from '@/shared/lib/utils/environment';

declare module '@nuxt/types' {
  interface Context {
    $portalApi?: NuxtAxiosInstance;
  }

  interface NuxtAppOptions {
    $portalApi?: NuxtAxiosInstance;
  }

  interface Configuration {
    portalApi?: NuxtAxiosInstance;
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $portalApi?: NuxtAxiosInstance;
  }
}

type Plugin = (ctx: Context, inject: Inject) => Promise<void> | void

let portalApiInstance: NuxtAxiosInstance | undefined;

const portalApiPlugin: Plugin = (ctx, inject) => {
  const {
    $config,
    $axios
  } = ctx;

  const API_PATH = '';
  let API_URL;

  if ($config.ENVIRONMENT === EnvironmentType.Local) {
    API_URL = process.client ? location.origin : `https://${ $config.MAIN_SITE_HOST }`;
  } else {
    API_URL = `https://${ $config.MAIN_SITE_HOST }`;
  }

  API_URL += API_PATH;
  portalApiInstance = $axios.create({
    baseURL: API_URL
  });

  inject('portalApi', portalApiInstance);
  ctx.$portalApi = portalApiInstance;
};

export default portalApiPlugin;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const usePortalApi = () => {
  if (!portalApiInstance) {
    throw new Error('Portal API instance is not defined :(');
  }

  return portalApiInstance;
};
