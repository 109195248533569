import {
  defineStore
} from 'pinia';

import type { StateChanger } from 'vue-infinite-loading';

import type { AxiosResponse } from 'axios';
import specialOffers from '@/queries/favoriteProperty.gql';
import getSimilarLots from '@/queries/getSimilarLots.gql';

import {
  globalToProfitbase,
  resortBy
} from '@/shared/lib/utils/misc';
import type {
  PageInfo
} from '@/types/PageInfo';
import type {
  FlatParams
} from '@/types/FlatParams';
import { THasPortalApi } from '@/types/Contexts';
import type {
  PathType
} from '@/shared/types/openapi/';

type SetLatestFavoritesResponse = Array<{
  id: number;
  clientId: number;
  propertyId: number;
  updatedAt: string;
  createdAt: string;
}>

interface FavoritesBySlugResponse {
  result: Array<string>;
  pageInfo: PageInfo;
}

interface Offer {
  id: number;
  createdAt: string;
  flatsCount?: number;
  offerLink: string;
}

interface State {
  favoritesRealtyIds: Array<string>;
  latestFavoritesIds: Array<string>;
  paramsFavoritesFlats: Array<FlatParams>;
  paramsLatestFavoritesFlats: Array<FlatParams>;
  offers: Array<Offer>;
  pageInfo: PageInfo | object;
}

export async function requestFlatsByIds (ctx: THasPortalApi, ids: Array<string>): Promise<Array<FlatParams>> {
  const {
    body: query
  } = specialOffers.loc.source;

  const response = await ctx.$portal.post('', {
    query,
    variables: {
      id: ids
    }
  });

  return response?.data?.data?.allStatusesFlats?.edges?.map(({ node }: { node: FlatParams }) => node) || [];
}

export const useFavoritesStore = defineStore('favorites', {
  state: (): State => ({
    favoritesRealtyIds: [],
    latestFavoritesIds: [],
    paramsFavoritesFlats: [],
    paramsLatestFavoritesFlats: [],
    offers: [],
    pageInfo: {}
  }),

  actions: {
    async getLatestFavorites (): Promise<void> {
      try {
        const url = 'api/favourites/latest/ids';
        const {
          data
        } = await this.$axios.get<Array<string>>(url);

        this.latestFavoritesIds = data;

        await this.getFlatsParams('latest');
      } catch (error) {
        console.log('🚀 ~ file: favorites.ts ~ getLatestFavorites ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async getFavoritesBySlug (slug: string): Promise<void> {
      try {
        const url = '/api/users/clients/interests';
        const {
          data: {
            result,
            pageInfo
          }
        } = await this.$axios.get<FavoritesBySlugResponse>(url, {
          params: {
            slug
          }
        });

        this.pageInfo = pageInfo;
        this.favoritesRealtyIds = result;

        await this.getFlatsParams('');
      } catch (error) {
        console.log('🚀 ~ file: favorites.ts ~ getFavoritesBySlug ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async nextPage (
      {
        page,
        infiniteState
      }: {
        page: PageInfo;
        infiniteState: StateChanger;
      }) {
      try {
        if (!page.next_page) {
          infiniteState.complete();

          return;
        }

        const url = new URL(page.next_page);
        const nextPage = url?.pathname + url?.search;

        const {
          data: {
            result,
            pageInfo
          }
        } = await this.$axios.get<FavoritesBySlugResponse>(nextPage);

        if (!pageInfo) {
          throw new Error('page info not received');
        }

        if (result?.length) {
          this.pageInfo = pageInfo;
          this.favoritesRealtyIds = [
            ...this.favoritesRealtyIds,
            ...result
          ];
          await this.getFlatsParams('');

          if (!pageInfo.next_page) {
            infiniteState.complete();
          } else {
            infiniteState.loaded();
          }
        } else {
          infiniteState.loaded();
        }
      } catch (error) {
        console.log('🚀 ~ file: favorites.ts ~ nextPage ~ error', error);
        infiniteState.complete();
        this.$sentry.captureException(error);
      }
    },

    async getFlatsParams (slug: string): Promise<void> {
      try {
        const idsField = slug === 'latest'
          ? 'latestFavoritesIds'
          : 'favoritesRealtyIds';
        const paramsField = slug === 'latest'
          ? 'paramsLatestFavoritesFlats'
          : 'paramsFavoritesFlats';

        const ids = this[idsField];

        if (!ids.length) {
          this[paramsField] = [];

          return;
        }

        const flats = await requestFlatsByIds(this, ids);

        if (!flats?.length) {
          this[paramsField] = [];
          throw flats;
        }


        this[paramsField] = resortBy<FlatParams, number>(
          flats, (item: FlatParams) => Number(item.pk), ids.map((globalId) => globalToProfitbase(globalId)));
      } catch (error) {
        console.log('🚀 ~ file: favorites.ts ~ getFlatsParams ~ error', error);
        this.$sentry.captureException(error);
      }
    },


    /**
     * Отправляет запрос на добавление в избранное
     * @param ids массив <u>`profitbase_id`</u> (pk, просто число), **НЕ** `global_id` (закодированное в base64 значение вида `sometext:somenumber`)
     * @remarks Важно, что `api/favourites/latest` принимает `global_id`, но `api/v2/favourites/latest - profitbase_id`
     */
    async setLatestFavorites (ids: Array<number>): Promise<SetLatestFavoritesResponse | null> {
      try {
        let data = null as null | SetLatestFavoritesResponse;
        const url = 'api/v2/favourites/latest';
        const batchSize = 5; // очень приблизительно, посравнивал просто максимальное время выполнения, с 5 вроде минимум

        if (ids.length > batchSize) {
          // т.к. запрос с большим количеством id выполняется слишком долго (случай: 32 id, время выполнения 40+ секунд, код уже не ждёт даже)
          // распилим запрос на n запросов с batchSize идшников в каждом (в последнем может быть меньше)
          type BatchesType = Array<Array<typeof ids[number]>>
          const batches = [] as BatchesType;

          for (let i = 0; i < ids.length; i++) {
            if (i % batchSize === 0) {
              batches.push([]);
            }
            batches[batches.length - 1].push(ids[i]);
          }

          const requests = [] as Array<Promise<AxiosResponse<SetLatestFavoritesResponse>>>;

          for (const batch of batches) {
            requests.push(this.$axios.post(url, batch));
          }

          const allSettled = Promise.allSettled(requests);

          data = await allSettled.then((promises) => {
            const results = [] as SetLatestFavoritesResponse;
            for (let i = 0; i < promises.length; i++) {
              const promise = promises[i];

              if (promise.status === 'fulfilled') {
                results.push(...promise.value.data as SetLatestFavoritesResponse);
              }
            }

            return results;
          });
        } else {
          data = (await this.$axios.post(url, ids)).data;
        }

        await this.getLatestFavorites();

        return data;
      } catch (error) {
        console.log('🚀 ~ file: favorites.ts ~ setLatestFavorites ~ error', error);
        this.$sentry.captureException(error);

        return null;
      }
    },

    async getSimilarFlats (id: string): Promise<FlatParams | undefined> {
      try {
        const {
          body: query
        } = getSimilarLots.loc.source;

        const {
          data: {
            data
          }
        } = await this.$portal.post('', {
          query,
          variables: {
            flatId: id,
            first: 1,
            tab: ''
          }
        });

        return data?.similarFlats?.edges?.[0]?.node ?? undefined;
      } catch (error) {
        console.log('🚀 ~ file: favorites.ts ~ getSimilarFlats ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    /**
     * Отправляет запрос на удаление из избранного
     * @param id <u>`profitbase_id`</u> (pk, просто число), **НЕ** `global_id` (закодированное в base64 значение вида `sometext:somenumber`)
     * @remarks Важно, что `api/users/clients/uninterests` принимает `global_id`, но `api/v2/users/clients/uninterests - profitbase_id`
     */
    async unfavorite (id: number): Promise<object> {
      try {
        const url = 'api/v2/users/clients/uninterests';
        await this.$axios.patch(url, [
          id
        ]);

        return {
          status: 'ok'
        };
      } catch (error) {
        console.log('🚀 ~ file: favorites.ts ~ unfavorite ~ error', error);
        this.$sentry.captureException(error);

        return {
          status: 'fail'
        };
      }
    },

    async getOffers () {
      try {
        const { data } = await this.$axios.get<PathType<'/offers', 'get', 200>>('api/offers');

        this.offers = data;
      } catch (error) {
        console.log('🚀 ~ file: favorites.ts ~ getOffers ~ error', error);
        this.$sentry.captureException(error);
      }
    }
  }
});
