import { defineStore } from 'pinia';
import {
  computed,
  ref,
  type Ref
} from 'vue';

import getErrorMessageTyped from '@@/common/services/getErrorMessageForNotificationDataTyped';
import type {
  NuxtAxiosInstance
} from '@nuxtjs/axios';
import type {
  SchemasType
} from '@/shared/types/openapi/';
import { useCitiesStore } from '@/store/cities';

import { AWSImage } from '@/types/AWSImage';

import {
  useSentry,
  useCabinetsApi
} from '@/app/plugins/app-context';
import { useAuthClientService } from '@/shared/api/Privilege/Privilege.client';


import type {
  PrivilegeInfo,
  PrivilegeRequest
} from '@/shared/api/Privilege/types';

interface Faq {
  id: number;
  question: string;
  answer: string;
  isActive: boolean;
  order: number;
  slug: string;
}

interface getFaqListResponse {
  result: Array<Faq>;
  count: number;
}

interface PrivilegeBenefit {
  title: string;
  slug: string;
  image: AWSImage;
  isActive: boolean;
  priority: number;
}

interface PartnerCompany {
    slug: string;
    title: string;
    description: string;
    link: string;
    image: AWSImage;
}

interface PrivilegeCategoryItem {
  'buttonLabel': string;
  'buttonLink': string;
  conditions: string;
  cooperationTypeId: string;
  description: string;
  'partnerCompanyId': string;
  partnerCompany : PartnerCompany;
  'priorityInSubcategory': number;
  promocode: string;
  slug: string;
  until: string;
  isActive:boolean;
}

interface PrivilegeDetails {
  'slug': string;
  'partnerCompanyId': string;
  'isActive': boolean;
  'priorityInSubcategory': number;
  'cooperationTypeId': string;
  'description': string;
  'conditions': string;
  'promocode': string;
  'buttonLabel': string;
  'buttonLink': string;
}


interface PrivilegeCategory {
  displayType: string;
  image: AWSImage;
  slug: string;
  title: string;
  results: Array<PrivilegeCategoryItem>;
}

type PrivilegesSpecs = SchemasType<'PrivilegeSpecsResponse'>;

export const usePrivilegeStore = defineStore('privilege', () => {
  const faqList: Ref<Array<Faq>> = ref([]);
  const privilegeInfo: Ref<Array<PrivilegeInfo>> = ref([]);
  const privilegeBenefits: Ref<Array<PrivilegeBenefit>> = ref([]);
  const privilegeCategories: Ref<Array<PrivilegeCategory>> = ref([]);
  const filteredPrivilegeCategories: Ref<Array<PrivilegeCategory>> = ref([]);
  const privilegeDetails: Ref<Partial<PrivilegeDetails>> = ref({});
  const privilegesSpecs: Ref<PrivilegesSpecs> = ref({ categories: [] });
  const axios: NuxtAxiosInstance = useCabinetsApi();
  const sentry = useSentry();
  const citiesStore = useCitiesStore();
  const currentCity = computed(() => citiesStore.currentCity?.value ?? undefined);
  const PrivilegeClient = useAuthClientService();

  async function getFaqList (): Promise<void> {
    try {
      const { data } = await axios.get<getFaqListResponse>('api/faq?pageTypeSlug=faq_client');

      if (data?.count > 0) {
        faqList.value = data.result;
      }
    } catch (error) {
      console.error('🚀 ~ file: privilege.ts ~ getFaqList ~ e', error);
      sentry?.captureException(error);
    }
  }

  async function getPrivilegeInfo (): Promise<void> {
    const data = await PrivilegeClient.getPrivilegeInfo();

    if (data?.length) {
      privilegeInfo.value = data;
    }
  }

  async function getPrivilegeBenefits (): Promise<void> {
    try {
      const { data } = await axios.get<Array<PrivilegeBenefit>>('api/privilege-program/benefits');

      if (data?.length > 0) {
        privilegeBenefits.value = data;
      }
    } catch (error) {
      console.error('🚀 ~ file: privilege.ts ~ getPrivilegeBenefits ~ e', error);
      sentry.captureException(error);
    }
  }

  async function getPrivilegeCategories (): Promise<void> {
    try {
      const { data } = await axios.get<Array<PrivilegeCategory>>('api/privilege-program', {
        params: {
          city: currentCity.value
        }
      });

      if (data?.length > 0) {
        privilegeCategories.value = data;
      } else {
        privilegeCategories.value = [];
      }
    } catch (error) {
      console.error('🚀 ~ file: privilege.ts ~ getPrivilegeCategories ~ e', error);
      sentry.captureException(error);
    }
  }

  async function getFilteredPrivilegeCategories (slugs:Array<string>): Promise<void> {
    try {
      if (slugs.length < 1) {
        filteredPrivilegeCategories.value = [];

        return;
      }

      const params = {
        categorySlug: slugs.includes('all') ? undefined : slugs,
        city: currentCity.value
      };
      const url = 'api/privilege-program';

      const { data } = await axios.get<PrivilegeCategory>(url, { params });

      if (!Array.isArray(data)) {
        filteredPrivilegeCategories.value = [data];
      } else {
        filteredPrivilegeCategories.value = data;
      }
    } catch (error) {
      console.error('🚀 ~ file: privilege.ts ~ getFilteredPrivilegeCategories ~ e', error);
      sentry.captureException(error);
    }
  }

  async function getPrivilegeDetails (slug:string): Promise<void> {
    try {
      const { data } = await axios.get<PrivilegeDetails>(`api/privilege-program/${ slug }`);

      if (data) {
        privilegeDetails.value = data;
      }
    } catch (error) {
      console.error('🚀 ~ file: privilege.ts ~ getPrivilegeDetails ~ e', error);
      sentry.captureException(error);
    }
  }

  async function getPrivilegesSpecs (): Promise<void> {
    try {
      const { data } = await axios.get<PrivilegesSpecs>('api/privilege-program/specs', {
        params: {
          city: currentCity.value
        }
      });

      if (data) {
        privilegesSpecs.value = data;
      }
    } catch (error) {
      console.error('🚀 ~ file: privilege.ts ~ getPrivilegeSpecs ~ e', error);
      sentry.captureException(error);
    }
  }

  async function sendForm (payload: PrivilegeRequest): Promise<void> {
    try {
      await axios.post<PrivilegeRequest>('/api/privilege-program/request', payload);
    } catch (error: unknown) {
      console.error('🚀 ~ file: privilege.ts ~ sendForm ~ error', error);
      sentry.captureException(error);

      if (axios.isAxiosError(error)) {
        const errorMessage = getErrorMessageTyped(error);
        throw new Error(errorMessage);
      }
    }
  }

  return {
    faqList,
    privilegeInfo,
    privilegeBenefits,
    privilegeCategories,
    filteredPrivilegeCategories,
    privilegeDetails,
    privilegesSpecs,
    getFaqList,
    getPrivilegeInfo,
    getPrivilegeBenefits,
    getPrivilegeCategories,
    sendForm,
    getFilteredPrivilegeCategories,
    getPrivilegesSpecs,
    getPrivilegeDetails
  };
});
