import Singletone from './Singleton';

export class DataLayer extends Singletone {
  constructor () {
    super();

    if (!Array.isArray(window.dataLayer)) {
      window.dataLayer = [];
    }

    this.dataLayer = window.dataLayer;
  }

  setUserId (userId) {
    this.dataLayer.push({
      user_id: userId
    });
  }

  addEvent (event, params = {}) {
    this.dataLayer.push({
      event,
      ...params
    });
  }
}
