import { defineStore } from 'pinia';
import getErrorMessageTyped from '@@/common/services/getErrorMessageForNotificationDataTyped';
import {
  CalculatorInfo,
  MortgageBooking,
  MortgageForm,
  Ticket,
  TicketStatus,
  Offer
} from '@/types/Mortgage';

interface State {
  mortgageBookings?: Array<MortgageBooking>;
  mortgageForm?: MortgageForm;
  mortgageTicketId?: number;
  tickets: Array<Ticket>;
  statuses: Array<TicketStatus>;
  calculatorInfoText?: string;
}

interface SendMortgageOffersPayload {
  bookingId: number;
  offers: Array<Offer>;
}

export const useMortgageStore = defineStore('mortgage', {
  state: (): State => ({
    mortgageBookings: [],
    mortgageForm: undefined,
    mortgageTicketId: undefined,
    tickets: [],
    statuses: [],
    calculatorInfoText: undefined
  }),

  getters: {},

  actions: {
    async getMortgageBookings (): Promise<void> {
      try {
        const token = this.$auth.strategy.token.get();
        const { data } = await this.$servicesApi.get<Array<MortgageBooking>>('/tickets/bookings', {
          headers: {
            Authorization: token
          }
        });

        this.mortgageBookings = data;
      } catch (error) {
        console.error('🚀 ~ file: mortgage.ts ~ getMortgageBookings ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    setMortgageForm (payload: MortgageForm): void {
      this.mortgageForm = payload;
    },

    async createTicket (payload: MortgageForm): Promise<void> {
      try {
        const token = this.$auth.strategy.token.get();
        const { data } = await this.$servicesApi.post<{id: number}>('/tickets/create_ticket', payload, {
          headers: {
            Authorization: token
          }
        });

        this.mortgageTicketId = data.id ?? undefined;
      } catch (error: unknown) {
        console.error('🚀 ~ file: mortgage.ts ~ createTicket ~ error', error);
        this.$sentry.captureException(error);
        const errorMessage = getErrorMessageTyped(error);
        throw new Error(errorMessage);
      }
    },

    async sendAmoData (payload: MortgageForm): Promise<void> {
      try {
        await this.$axios.post('/api/mortgage/send_amo_data', payload);
      } catch (error: unknown) {
        console.error('🚀 ~ file: mortgage.ts ~ sendAmoData ~ error', error);
        this.$sentry.captureException(error);
        const errorMessage = getErrorMessageTyped(error);
        throw new Error(errorMessage);
      }
    },

    async getTickets (): Promise<void> {
      try {
        const token = this.$auth.strategy.token.get();
        const { data } = await this.$servicesApi.get('/tickets/all', {
          headers: {
            Authorization: token
          }
        });

        this.tickets = data.tickets;
        this.statuses = data.statuses;
      } catch (error) {
        console.error('🚀 ~ file: mortgage.ts ~ getTickets ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async getCalculatorInfo (city: string): Promise<void> {
      if (!city) {
        return;
      }

      try {
        const { data } = await this.$axios.get<CalculatorInfo | null>(`/api/mortgage/text_blocks/${ city }`);

        if (data) {
          this.calculatorInfoText = data.text;
        }
      } catch (error) {
        console.error('🚀 ~ file: mortgage.ts ~ getCalculatorInfo ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async sendMortgageOffers (payload: SendMortgageOffersPayload): Promise<void> {
      try {
        const token = this.$auth.strategy.token.get();
        await this.$servicesApi.post('/tickets/mortgage_offers', payload, {
          headers: {
            Authorization: token
          }
        });
      } catch (error) {
        console.error('🚀 ~ file: mortgage.ts ~ sendMortgageOffers ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async validateSms (payload: { bookingId: number }): Promise<{ ok: boolean }> {
      try {
        const { data } = await this.$axios.post('/api/booking/validate_sms', payload);

        return data;
      } catch (error: unknown) {
        console.error('🚀 ~ file: mortgage.ts ~ validateSms ~ error', error);
        this.$sentry.captureException(error);
        const errorMessage = getErrorMessageTyped(error);
        throw new Error(errorMessage);
      }
    },

    async uploadDocuments (payload: MortgageForm): Promise<void> {
      try {
        await this.$axios.post('/api/mortgage/upload_documents', payload);
      } catch (error: unknown) {
        console.error('🚀 ~ file: mortgage.ts ~ uploadDocuments ~ error', error);
        this.$sentry.captureException(error);
        const errorMessage = getErrorMessageTyped(error);
        throw new Error(errorMessage);
      }
    },

    async checkDvizh (phone: string): Promise<boolean | undefined> {
      try {
        const token = this.$auth.strategy.token.get();
        const { data } = await this.$servicesApi.post<{codeRequired: boolean}>('/tickets/check_dvizh', {
          phone
        }, {
          headers: {
            Authorization: token
          }
        });

        return data?.codeRequired;
      } catch (error) {
        console.error('🚀 ~ file: mortgage.ts ~ checkDvizh ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async sendCodeDvizh (phone: string): Promise<void> {
      try {
        const token = this.$auth.strategy.token.get();
        await this.$servicesApi.post('/tickets/send_code_dvizh', {
          phone
        }, {
          headers: {
            Authorization: token
          }
        });
      } catch (error) {
        console.error('🚀 ~ file: mortgage.ts ~ sendCodeDvizh ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async validateCodeDvizh (phone: string, code: string): Promise<void> {
      try {
        const token = this.$auth.strategy.token.get();
        await this.$servicesApi.post<{codeRequired: boolean}>('/tickets/validate_code_dvizh', {
          phone,
          code
        }, {
          headers: {
            Authorization: token
          }
        });
      } catch (error) {
        console.error('🚀 ~ file: mortgage.ts ~ validateCodeDvizh ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    }
  }
});
