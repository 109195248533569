import { defineStore } from 'pinia';
import getErrorMessageTyped from '@@/common/services/getErrorMessageForNotificationDataTyped';
import { AWSImage } from '@/types/AWSImage';

interface LoginSliderItem {
  id: number;
  title: string;
  subtitle: string;
  desktopMedia: AWSImage;
  tabletMedia: AWSImage;
  mobileMedia: AWSImage;
}

interface State {
  phone?: string;
  token?: string;
  loginSliderItems: Array<LoginSliderItem>;
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    phone: undefined,
    token: undefined,
    loginSliderItems: []
  }),

  getters: {},

  actions: {
    async sendCode (payload: { phone: string }): Promise<void> {
      const url = '/api/users/send_code';
      try {
        const { data } = await this.$axios.post<{token: string}>(url, payload);

        this.token = data.token;
        this.phone = payload.phone;
      } catch (error) {
        this.$sentry.captureException(error);
        throw new Error('Введите корректный номер телефона');
      }
    },

    async login (code: string, redirect: boolean = true): Promise<void> {
      try {
        await this.$auth.loginWith('users', {
          data: {
            phone: this.phone,
            token: this.token,
            code
          }
        });

        if (redirect) {
          await this.$router.push({
            name: 'index'
          });
        }
        // eslint-disable-next-line
      } catch (error: any) {
        this.$sentry.captureException(error);
        const errorMessage = getErrorMessageTyped(error);
        throw new Error(errorMessage);
      }
    },

    async logout (): Promise<void> {
      try {
        await this.$auth.logout();
      } catch (error) {
        console.log('🚀 ~ file: authentication.js ~ logout ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async getLoginSlider (): Promise<void> {
      try {
        const { data } = await this.$axios.get<Array<LoginSliderItem>>('api/dashboard/slides');

        this.loginSliderItems = data;
      } catch (error) {
        console.log('🚀 ~ file: authentication.js ~ getLoginSlider ~ error', error);
        this.$sentry.captureException(error);
      }
    }
  }
});
