// region Imports
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { AxiosResponse } from 'axios';

import { ERROR_MESSAGE } from './constants';
import { useSentry } from '@/app/plugins/app-context';
// endregion

abstract class BaseClient {
  #apiInstance: NuxtAxiosInstance;
  protected sentry: ReturnType<typeof useSentry>;

  protected constructor ($apiInstance: NuxtAxiosInstance) {
    this.#apiInstance = $apiInstance;
    this.sentry = useSentry();
  }

  protected async sendGetRequest<ResponseType> (
    url: string,
    query: Record<string, unknown> = {}
  ): Promise<ResponseType> {
    const response = await this.#apiInstance.get<ResponseType>(
      url, { params: query }
    );

    if (!response) {
      throw new Error(ERROR_MESSAGE);
    }

    return response.data;
  }

  protected async sendPostRequest<ResponseType = AxiosResponse> (
    url: string,
    // eslint-disable-next-line
    data: any,
    query: Record<string, unknown> = {}
  ): Promise<ResponseType> {
    const response = await this.#apiInstance.post<ResponseType>(
      url, data, { params: query }
    );

    if (!response) {
      throw new Error(ERROR_MESSAGE);
    }

    return response.data;
  }
}

export { BaseClient };
