
import {
  NameOutput
} from '@strana-artw/cabinets-common';
import { SIcon } from '@strana-artw/ui-kit';
import {
  getMeFromStore
} from '@@/common/services/user';

export default {
  name: 'UserCard',

  components: {
    SIcon,
    NameOutput
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  emits: ['click'],

  computed: {
    // Для отрисовки скелета
    classes () {
      return [{
        [this.$style.loading]: this.isLoading
      }];
    },

    loggedIn () {
      return this.$auth.loggedIn;
    },

    profileName () {
      if (this.loggedIn) {
        const {
          name,
          surname,
          patronymic
        } = this.getMeFromStore();

        return {
          surname,
          name,
          patronymic
        };
      }

      return {};
    }
  },

  methods: {
    getMeFromStore,

    onCard () {
      this.$emit('click');
    }
  }
};
