
import { toRefs } from 'vue';
import CityModal from '@/components/common/header-select-city/CityModal';
import CitySelect from '@/components/common/header-select-city/CitySelect';
import { useScreenPlugin } from '@/app/plugins/vueScreen';

export default {
  name: 'HeaderSelectCity',

  components: {
    CitySelect,
    CityModal
  },

  props: {
    cities: {
      type: Array,
      default: () => []
    },

    value: {
      type: String,
      default: undefined
    }
  },

  emits: [
    'input',
    'select',
    'modal'
  ],

  setup () {
    const { isMobileOrTablet } = toRefs(useScreenPlugin());

    return {
      isMobileOrTablet
    };
  },

  data () {
    return {
      isCitiesShown: false
    };
  },

  computed: {
    city: {
      get () {
        return this.value;
      },

      set (value) {
        this.$emit('input', value);
      }
    }
  },

  methods: {
    onClose () {
      this.isCitiesShown = false;
      this.$emit('modal', this.isCitiesShown);
    },

    toggleDisplay () {
      this.isCitiesShown = !this.isCitiesShown;
      this.$emit('modal', this.isCitiesShown);
    },

    onSelect (value) {
      this.onClose();
      this.$emit('select', value);
    }
  }
};
