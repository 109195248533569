/*
  Эта функция нужна для того, чтобы синхронизировать текущий город из куки с портала,
  в момент загрузки страниц кабинета
*/

import {
  getCookieFromString,
  removeCookie
} from '@/shared/lib/utils/helpers';
import { defineNuxtPlugin } from '@/types/nuxt2CompositionApiShims';
import {
  useCitiesStore
} from '@/store/cities';

export default defineNuxtPlugin(async (context) => {
  const cookieName = 'slug_city';

  const cookieValue = getCookieFromString(document.cookie, cookieName);

  const {
    getCities,
    getCurrentCity,
    setCurrentCity
  } = useCitiesStore(context.$pinia) ?? {};

  if (!cookieValue) {
    await getCurrentCity?.();

    return;
  }

  await getCities?.();
  setCurrentCity?.(cookieValue);

  removeCookie(cookieName);
});
