import { defineStore } from 'pinia';
import {
  useCitiesStore
} from '@/store/cities';

interface Icon {
  src: string;
  s3: string;
  aws: string;
}

interface MenuItem {
  id: number;
  name: string;
  link: string;
  icon: Icon;
  hideDesktop: boolean;
}

interface MenuResult {
  top: Array<MenuItem>;
  right: Array<MenuItem>;
}

interface GetMenuResponseNewMenu {
  result: MenuResult;
}

interface State {
  menu: Array<MenuItem>;
}
export const useMenuStore = defineStore('menu', {
  state: (): State => ({
    menu: []
  }),

  getters: {},

  actions: {
    async getMenu (): Promise<void> {
      const cities = useCitiesStore();

      const {
        value: city
      } = cities.currentCity ?? {};

      if (!city) {
        return;
      }

      try {
        const {
          result
        } = await this.$axios.$get<GetMenuResponseNewMenu>('api/v2/menu', {
          params: {
            city,
            menu_type: 'lk_client'
          }
        });
        this.menu = result.top;
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: menu.ts ~ getMenu ~ e', error);
      }
    }
  }
});
