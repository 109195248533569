import axios from 'axios';

function getErrorMessageTyped (
  error: unknown,
  fallback = 'Произошла ошибка. Попробуйте через несколько минут'
): string {
  if (axios.isAxiosError(error)) {
    const preparedFallback = error.response?.status
      ? `${ fallback } (${ error.response?.status })`
      : fallback;

    return error.response?.data?.message || error.message || preparedFallback;
  }

  return fallback;
}

export default getErrorMessageTyped;
