import type {
  Location
} from 'vue-router';
import { resolveURL } from '@/shared/lib/utils/resolveURL';
import type {
  Booking
} from '@/types/Booking';

type Dictionary<T> = Record<string, T>;

const resolveTaskLink = (link: string | undefined, query?: Dictionary<string | Array<string | null>>): string => {
  if (link) {
    const queryString = query ? `?${ new URLSearchParams(query as Record<string, string>).toString() }` : '';
    const _link = resolveURL(link) as Location;

    if (queryString && _link?.name !== 'external-link-redirect') {
      return `${ _link?.path }${ queryString }`;
    }

    return _link?.path ?? '';
  }

  return '';
};

const resolveMainTaskLink = (bookingData: Booking): string => {
  if (bookingData?.id) {
    const mainTask = bookingData?.tasks?.find(({ isMain }) => Boolean(isMain));

    const {
      buttons,
      id
    } = mainTask ?? {};

    if (buttons?.length) {
      return resolveTaskLink(buttons[0]?.action?.type, {
        bookingId: String(bookingData.id),
        taskId: String(id)
      });
    }
  }

  return '/reservations/';
};

export {
  resolveTaskLink,
  resolveMainTaskLink
};
