
import {
  lockBody,
  unlockBody
} from '@/shared/lib/utils/helpers';

export default {
  name: 'VNavigationDrawer',
  methods: {
    close () {
      this.$emit('close');
    },

    beforeEnter () {
      this.$emit('beforeEnter');
      lockBody();
    },

    afterLeave () {
      unlockBody();
    }
  }
};
