
import {
  mapActions
} from 'pinia';

import Gear from '@/assets/sprite/svg/gear-gray.svg';
import Out from '@/assets/sprite/svg/out-gray.svg';
import HeaderProfileMenuItem from '@/components/common/header-profile/HeaderProfileMenuItem';
import { useAuthStore } from '@/store/authentication';

export default {
  name: 'HeaderProfileMenu',

  components: {
    HeaderProfileMenuItem
  },

  computed: {
    items () {
      return [
        {
          label: 'Профиль',
          icon: Gear,
          action: () => this.$router.push({
            name: 'index-account'
          })
        },
        {
          label: 'Выйти',
          icon: Out,
          action: () => this.logout()
        }
      ];
    }
  },

  methods: {
    ...mapActions(useAuthStore, [
      'logout'
    ])
  }
};
