
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  useCssModule
} from 'vue';

export default defineComponent({
  name: 'LayoutPrint',

  setup () {
    const $style = useCssModule();

    onMounted(() => {
      document.documentElement.classList.add($style.htmlRoot);
    });

    onBeforeUnmount(() => {
      document.documentElement.classList.remove($style.htmlRoot);
    });
  }
});
