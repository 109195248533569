import { render, staticRenderFns } from "./index.vue?vue&type=template&id=00405e2c"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=00405e2c&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProfileOutput: require('/app/src/components/common/header-profile/ProfileOutput.vue').default,HeaderProfileMenu: require('/app/src/components/common/header-profile/HeaderProfileMenu.vue').default})
