import { render, staticRenderFns } from "./CallbackModal.vue?vue&type=template&id=67050e86"
import script from "./CallbackModal.vue?vue&type=script&lang=js"
export * from "./CallbackModal.vue?vue&type=script&lang=js"
import style0 from "./CallbackModal.vue?vue&type=style&index=0&id=67050e86&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CallbackModalForm: require('/app/src/components/common/modals/callback/CallbackModalForm.vue').default,CallbackModalSuccess: require('/app/src/components/common/modals/callback/CallbackModalSuccess.vue').default})
