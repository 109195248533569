import { defineStore } from 'pinia';
import { formatUserPersonal } from '@@/common/services/user';
import type { UserData } from '@/types/UserData';

const endpoints = {
  updateMe: '/api/users/update_personal'
};

interface PatchUserResponse {
  ok: boolean;
  // eslint-disable-next-line
  error: any;
  userData?: UserData;
}

interface State {
  patchUserResponse?: PatchUserResponse;
}

export const useUserStore = defineStore('user', {
  state: (): State => ({
    patchUserResponse: undefined
  }),

  getters: {},

  actions: {
    async patchUser (payload: Partial<UserData>): Promise<void> {
      try {
        const { data } = await this.$axios.patch<UserData>(endpoints.updateMe, formatUserPersonal(payload));

        this.patchUserResponse = {
          ok: true,
          error: null,
          userData: data
        };
      } catch (e) {
        this.patchUserResponse = {
          ok: false,
          error: e
        };

        console.error('🚀 ~ file: user.ts ~ patchUser ~ e', e);
        this.$sentry.captureException(e);
      }
    }
  }
});
