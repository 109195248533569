export function getScrollPosition (scrollTarget: Window | HTMLElement): number {
  if (scrollTarget === window) {
    return window.pageYOffset || window.scrollY || document.body.scrollTop || 0;
  }

  return (scrollTarget as HTMLElement).scrollTop || 0;
}

export function getHorizontalScrollPosition (scrollTarget: Window | HTMLElement): number {
  if (scrollTarget === window) {
    return window.pageXOffset || window.scrollX || document.body.scrollLeft || 0;
  }

  return (scrollTarget as HTMLElement).scrollLeft || 0;
}

export function hasScrollbar (el: HTMLElement, onY: boolean = true): boolean {
  if (!el || el.nodeType !== Node.ELEMENT_NODE) {
    return false;
  }

  const computedStyle = window.getComputedStyle(el);
  const overflowProperty = onY ? 'overflow-y' : 'overflow-x';
  const scrollProperty = onY ? 'scrollHeight' : 'scrollWidth';
  const clientProperty = onY ? 'clientHeight' : 'clientWidth';

  return el[scrollProperty] > el[clientProperty] && (
    el.classList.contains('scroll') ||
    el.classList.contains('overflow-auto') ||
    ['auto', 'scroll'].includes(computedStyle.getPropertyValue(overflowProperty))
  );
}
