import { defineStore } from 'pinia';
import { useCitiesStore } from '@/store/cities';

interface BaseElement {
  id: number;
  type: string;
  width: number;
  title: string;
  description: string;
  image: string;
  enableFos: boolean;
}

interface DashboardElement extends BaseElement {
  link: string;
  expires: string;
}

interface DashboardBlock extends BaseElement {
  elements: Array<DashboardElement>;
}

interface DashboardElementResponse extends BaseElement {
  elementsList: Array<DashboardElement>;
}

interface State {
  dashboard?: Array<DashboardBlock>;
}

export const useDashboardStore = defineStore('dashboard', {
  state: (): State => ({
    dashboard: undefined
  }),

  getters: {
    dashboardFiltered (
      { dashboard }
    ): Array<DashboardBlock> | undefined {
      return dashboard
        ?.map(({
          type,
          elements,
          ...rest
        }: DashboardBlock) => {
          if (type !== 'stock_slider') {
            return {
              ...rest,
              elements,
              type
            };
          }

          const filteredElements = elements
            .filter(({ expires }: DashboardElement) => {
              if (!expires) {
                return false;
              }

              const expiresTime = new Date(expires).getTime();
              const todayTime = new Date().getTime();
              const isExpired = todayTime > expiresTime;

              return !isExpired;
            });

          return {
            ...rest,
            elements: filteredElements,
            type
          };
        });
    }
  },

  actions: {
    async getDashboard (): Promise<void> {
      try {
        const cities = useCitiesStore();

        const {
          value: city
        } = cities.currentCity ?? {};

        if (!city) {
          return;
        }

        const {
          data
        } = await this.$axios.get<Array<DashboardElementResponse>>('api/dashboard', {
          params: {
            city
          }
        });

        this.dashboard = data.map(
          ({
            elementsList: elements,
            ...rest
          }) => ({
            elements,
            ...rest
          })
        );
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: dashboard/index.ts ~ getDashboard ~ e', error);
      }
    }
  }
});
