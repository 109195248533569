// toDo заменить на npm пакет
import {
  getEventPath,
  listenOpts,
  stopAndPrevent
} from '@/shared/lib/utils/eventUtils';
import {
  hasScrollbar,
  getScrollPosition,
  getHorizontalScrollPosition
} from '@/shared/lib/utils/scrollUtils';
// import { client } from '../plugins/Platform.js';

let scrollPositionX;
let scrollPositionY;
let maxScrollTop;
let vpPendingUpdate = false;
let bodyLeft;
let bodyTop;
let closeTimer;

function onWheel (e) {
  if (shouldPreventScroll(e)) {
    stopAndPrevent(e);
  }
}

function shouldPreventScroll (e) {
  if (
    e.target === document.body ||
        e.target.classList.contains('q-layout__backdrop')
  ) {
    return true;
  }

  const path = getEventPath(e);
  const shift = e.shiftKey && !e.deltaX;
  const scrollY = !shift && Math.abs(e.deltaX) <= Math.abs(e.deltaY);
  const delta = shift || scrollY ? e.deltaY : e.deltaX;

  for (let index = 0; index < path.length; index++) {
    const el = path[index];

    if (hasScrollbar(el, scrollY)) {
      return scrollY
        ? delta < 0 && el.scrollTop === 0
          ? true
          : delta > 0 &&
                      el.scrollTop + el.clientHeight === el.scrollHeight
        : delta < 0 && el.scrollLeft === 0
          ? true
          : delta > 0 &&
                  el.scrollLeft + el.clientWidth === el.scrollWidth;
    }
  }

  return true;
}

function onAppleScroll (e) {
  if (e.target === document) {
    // required, otherwise iOS blocks further scrolling
    // until the mobile scrollbar dissappears
    document.scrollingElement.scrollTop =
            document.scrollingElement.scrollTop; // eslint-disable-line
  }
}

function onAppleResize (evt) {
  if (vpPendingUpdate === true) {
    return;
  }

  vpPendingUpdate = true;

  requestAnimationFrame(() => {
    vpPendingUpdate = false;

    const { height } = evt.target;
    const { clientHeight, scrollTop } = document.scrollingElement;

    if (maxScrollTop === undefined || height !== window.innerHeight) {
      maxScrollTop = clientHeight - height;
      document.scrollingElement.scrollTop = scrollTop;
    }

    if (scrollTop > maxScrollTop) {
      document.scrollingElement.scrollTop -= Math.ceil((scrollTop - maxScrollTop) / 8);
    }
  });
}

function apply (action) {
  const { body } = document;

  // Задача strana_lk-688
  // При открытии/скрытия модалки в лк брокера, дергается шапка.
  // Добавляем отступа для шапки
  const header = document.getElementById('broker-header');

  const hasViewport = window.visualViewport !== undefined;

  if (action === 'add') {
    scrollPositionX = getHorizontalScrollPosition(window);
    scrollPositionY = getScrollPosition(window);
    bodyLeft = body.style.left;
    bodyTop = body.style.top;

    body.style.left = `-${ scrollPositionX }px`;
    body.style.top = `-${ scrollPositionY }px`;

    // Высчитываем ширину скролла
    const documentWidth = parseInt(document.documentElement.clientWidth, 10);
    const windowsWidth = parseInt(window.innerWidth, 10);
    const scrollbarWidth = windowsWidth - documentWidth;

    if (scrollbarWidth > 0) {
      body.style.paddingRight = `${ scrollbarWidth }px`; // добавляем отступ если прокрутка заблокирована
    }

    if (header) {
      header.style.paddingRight = `${ scrollbarWidth }px`;
    }

    // if (
    // overflowY !== 'hidden' && overflowY === 'scroll' ||
    //   body.scrollHeight > window.innerHeight
    // ) {
    //   body.classList.add('body--force-scrollbar');
    //   document.documentElement.classList.add('body--force-scrollbar');
    // }

    body.classList.add('body--prevent-scroll');
    document.qScrollPrevented = true;


    if (window.$nuxt.$device.isIos === true) {
      if (hasViewport === true) {
        window.scrollTo(0, 0);
        window.visualViewport.addEventListener('resize', onAppleResize, listenOpts.passiveCapture);
        window.visualViewport.addEventListener('scroll', onAppleResize, listenOpts.passiveCapture);
        window.scrollTo(0, 0);
      } else {
        window.addEventListener('scroll', onAppleScroll, listenOpts.passiveCapture);
      }
    }
  }

  if (window.$nuxt.$device.isDesktop === true && window.$nuxt.$device.isMacOS === true) {
    // ref. https://developers.google.com/web/updates/2017/01/scrolling-intervention
    window[`${ action }EventListener`]('wheel', onWheel, listenOpts.notPassive);
  }

  if (action === 'remove') {
    if (window.$nuxt.$device.isIos === true) {
      if (hasViewport === true) {
        window.visualViewport.removeEventListener('resize', onAppleResize, listenOpts.passiveCapture);
        window.visualViewport.removeEventListener('scroll', onAppleResize, listenOpts.passiveCapture);
      } else {
        window.removeEventListener('scroll', onAppleScroll, listenOpts.passiveCapture);
      }
    }

    if (header) {
      header.style.paddingRight = '';
    }

    body.classList.remove('body--prevent-scroll');
    body.classList.remove('body--force-scrollbar');
    // document.documentElement.classList.remove('body--force-scrollbar');

    document.qScrollPrevented = false;

    body.style.left = bodyLeft;
    body.style.top = bodyTop;
    body.style.paddingRight = ''; // убираем отступ если есть скролл

    window.scrollTo(scrollPositionX, scrollPositionY);
    maxScrollTop = undefined;
  }
}

let blocked = false;
export function preventScroll (state, options = { block: null }) {
  const { block } = options ?? {};

  if (typeof block === 'boolean') {
    blocked = block;
  }

  if (blocked && !block) {
    return;
  }

  let action = 'add';

  if (state === true) {
    //     registered++;

    if (closeTimer !== undefined) {
      clearTimeout(closeTimer);
      closeTimer = undefined;

      return;
    }

    //     if (registered > 1) {
    //         return;
    //     }
  } else {
    //     if (registered === 0) {
    //         return;
    //     }

    //     registered--;

    //     if (registered > 0) {
    //         return;
    //     }

    action = 'remove';

    if (window.$nuxt.$device.isIos === true) {
      // && client.is.nativeMobile === true
      clearTimeout(closeTimer);

      closeTimer = setTimeout(() => {
        apply(action);
        closeTimer = undefined;
      }, 100);

      return;
    }
  }

  apply(action);
}

export default {
  methods: {
    __preventScroll (state) {
      if (
        state !== this.preventedScroll &&
                (this.preventedScroll !== undefined || state === true)
      ) {
        this.preventedScroll = state;
        preventScroll(state);
      }
    }
  }
};
