
import CallbackModalForm from '@/components/common/modals/callback/CallbackModalForm.vue';
import CallbackModalSuccess from '@/components/common/modals/callback/CallbackModalSuccess.vue';

export default {
  name: 'CallbackModal',

  components: {
    CallbackModalForm,
    CallbackModalSuccess
  },

  model: {
    prop: 'open',
    event: 'update:open'
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      required: true
    },

    type: {
      type: String,
      required: true,
      validator (value) {
        return [
          'callback',
          'parking'
        ].includes(value);
      }
    },

    cardTitle: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      isCallbackModalFormOpen: false,
      isCallbackModalSuccessOpen: false,
      isLoading: false
    };
  },

  computed: {
    isModalShown () {
      return !(this.isCallbackModalFormOpen || this.isCallbackModalSuccessOpen);
    }
  },

  watch: {
    open (value) {
      this.$nextTick(() => {
        this.isCallbackModalFormOpen = value;
      });
    },

    isModalShown (value) {
      if (value) {
        this.$emit('update:open', false);
      }
    }
  },

  methods: {
    formConfirmHandler () {
      this.isCallbackModalSuccessOpen = true;
    }
  }
};
