import { render, staticRenderFns } from "./FixedLayoutWithLeftBlockMenu.vue?vue&type=template&id=044cb95b"
import script from "./FixedLayoutWithLeftBlockMenu.vue?vue&type=script&lang=js"
export * from "./FixedLayoutWithLeftBlockMenu.vue?vue&type=script&lang=js"
import style0 from "./FixedLayoutWithLeftBlockMenu.vue?vue&type=style&index=0&id=044cb95b&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/app/src/components/layout/TheHeader.vue').default,TheBurgerMenu: require('/app/src/components/layout/TheBurgerMenu.vue').default,CustomTemplateWithLeftBlock: require('/app/common/components/common/templates/CustomTemplateWithLeftBlock.vue').default})
