

import Badge from '@/components/common/Badge.vue';
export default {
  name: 'HeaderCountIcon',

  components: {
    Badge
  },

  props: {
    count: {
      type: Number,
      default: 0
    },

    maxCount: {
      type: Number,
      default: 99
    }
  },

  computed: {
    label () {
      return this.count <= this.maxCount
        ? this.count
        : `${ this.maxCount }+`;
    }
  }
};
