
export default {
  props: {
    to: {
      type: String,
      default: ''
    },

    href: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: ''
    },

    fontSize: {
      type: String,
      default: '1.4rem'
    }
  },

  computed: {
    isCurrentPage () {
      let route = '';
      let currentRoute = this.$route.path;

      if (this.componentObj.tag === 'a') {
        route = this.href;
      }

      if (this.componentObj.tag === 'n-link') {
        route = this.to;
      }

      if (!currentRoute.endsWith('/')) {
        currentRoute += '/';
      }

      if (!route.endsWith('/') && this.$route.path.endsWith('/')) {
        route += '/';
      }

      return currentRoute.endsWith(route);
    },

    componentObj () {
      if (this.href) {
        return {
          tag: 'a',
          props: {
            href: this.href
          }
        };
      }

      return {
        tag: 'n-link',
        props: {
          to: this.to
        }
      };
    },

    styles () {
      return {
        height: this.fontSize,
        'font-size': this.fontSize
      };
    }
  }
};
