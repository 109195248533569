import { defineStore } from 'pinia';
import {
  ref,
  type Ref
} from '@nuxtjs/composition-api';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { AWSImage } from '@/types/AWSImage';
import {
  useSentry,
  useCabinetsApi
} from '@/app/plugins/app-context';

export interface StoryResponse {
  id: number;
  previewImage: AWSImage;
  hasUnread: boolean;
  title?: string;
  titleColor: string | null;
}

interface ButtonSlide {
  text: string;
  link: string;
}

export interface StorySlide {
  id: number;
  title?: string;
  titleColor: string;
  description: string | null;
  descriptionColor: string;
  button: ButtonSlide;
  image: AWSImage;
  displayTimingSeconds: number;
  isViewed: boolean;
}

export const useDashboardStoriesStore = defineStore('dashboardStories', () => {
  const axios: NuxtAxiosInstance = useCabinetsApi();
  const sentry = useSentry();
  const stories: Ref<Array<StoryResponse>> = ref([]);
  const sessionReadSlide: Ref<Array<number>> = ref([]);
  const activeStory: Ref<Array<StorySlide>> = ref([]);
  const indexActiveStory: Ref<number> = ref(0);

  async function getStories (): Promise<void> {
    try {
      const { data } = await axios.get<Array<StoryResponse>>('api/dashboard/story');
      stories.value = data;
    } catch (error) {
      console.error('🚀 ~ file: stories.ts ~ getStories ~ e', error);
      sentry.captureException(error);
    }
  }

  async function getStory (storyId: number): Promise<void> {
    try {
      const { data } = await axios.get<Array<StorySlide>>('api/dashboard/story_slide', {
        params: {
          story_id: storyId
        }
      });
      activeStory.value = data;

      if (data?.length > 0) {
        indexActiveStory.value = stories.value.map(({ id }) => id).indexOf(storyId);
      }
    } catch (error) {
      console.error('🚀 ~ file: stories.ts ~ getStory ~ e', error);
      sentry.captureException(error);
    }
  }

  async function setReadSlide (slideId: number): Promise<void> {
    try {
      if (sessionReadSlide.value.includes(slideId)) {
        return;
      }

      await axios.post<void>(`api/dashboard/story_slide/${ slideId }/view`);
      sessionReadSlide.value.push(slideId);
    } catch (error) {
      console.error('🚀 ~ file: stories.ts ~ setReadSlide ~ e', error);
      sentry.captureException(error);
    }
  }

  async function resetReadSlide (): Promise<void> {
    sessionReadSlide.value = [];
  }

  return {
    stories,
    activeStory,
    indexActiveStory,
    setReadSlide,
    getStories,
    getStory,
    resetReadSlide
  };
});
