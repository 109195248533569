import { defineStore } from 'pinia';
import { preventScroll } from '@@/common/mixins/prevent-scroll';

interface State {
  isOpenedMenu: boolean;
}

export const useHeaderStore = defineStore('header', {
  state: (): State => ({
    isOpenedMenu: false
  }),

  getters: {},

  actions: {
    setHeaderMenuState (menuState: boolean): void {
      if (this.isOpenedMenu !== menuState) {
        preventScroll(menuState);
        this.isOpenedMenu = menuState;
      }
    }
  }
});
