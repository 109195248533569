

export default {
  name: 'Badge',

  props: {
    label: {
      type: [Number, String],
      default: undefined
    },

    color: {
      type: String,
      default: undefined
    },

    size: {
      type: String,
      default: '18px'
    }
  }
};
