import { render, staticRenderFns } from "./index.vue?vue&type=template&id=e38bd448"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=e38bd448&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CitySelect: require('/app/src/components/common/header-select-city/CitySelect.vue').default,CityModal: require('/app/src/components/common/header-select-city/CityModal.vue').default})
