import Vue from 'vue';
import Notifications from '~notify/Notify.vue';

/* eslint-disable no-unused-expressions,no-use-before-define,brace-style */

class Notify {
    constructor(options) {
        this.options = options;
        this.__vm = new Vue(Notifications);
        this.create = function(opts) {
            return this.__vm.add(opts, this.options);
        };
        this.create.bind(this)
        this.registerType = function(typeName, typeOpts) {
            this.options.types[typeName] = typeOpts;
        };
    }
}

export default function({app}, inject) {
    const options = {"types":{"positive":{"icon":function() {
        return 'i-success';
      },"color":"positive","position":"top-right","actions":[{"iconName":"close","SButtonProps":{"theme":"ghost","size":"x-small"}}]},"negative":{"icon":function() {
        return 'i-errorFilled';
      },"color":"negative","position":"top-right","timeout":3000,"actions":[{"iconName":"close","SButtonProps":{"theme":"ghost","size":"x-small"}}]},"warning":{"icon":function() {
        return 'i-error';
      },"color":"warning","position":"top-right","actions":[{"iconName":"close","SButtonProps":{"theme":"ghost","size":"x-small"}}]},"info":{"icon":function() {
        return 'i-notification';
      },"type":"info","color":"info","position":"top-right","actions":[{"iconName":"close","SButtonProps":{"theme":"secondary","size":"x-small"}}]},"ongoing":{"group":false,"timeout":3000,"spinner":true,"color":"grey-8"}}};
    const notifyPlugin = new Notify(options);
    app.$notify = notifyPlugin;
    inject('notify', notifyPlugin);
    if (!app.mixins) {
        app.mixins = [];
    }
    app.mixins.push({
        mounted() {
            const node = document.createElement('div');
            document.body.appendChild(node);
            notifyPlugin.__vm.$mount(node);
        },
    });
}
