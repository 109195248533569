import { base64ToString } from './base64Utils';

/**
 * Конвертирует global id (строка вида `somestring:somenumber`) в profitbase id (просто число)
 * @param globalId global id, `string`
 * @returns Profitbase Id (pk), `number`
 */
export function globalToProfitbase (globalId: string): number {
  const decoded = base64ToString(globalId);

  return Number(decoded.substring(decoded.lastIndexOf(':') + 1));
}

/**
 * Создает новую коллекцию, состоящую из элементов `collection` отсортированную по порядку `order`,
 * в качестве критерия для сортировки (по чем сортировать) - геттер `criteria`
 * @remarks Элементы, `criteria` которых не встретился в `order`, будут отброшены
 * @param collection коллекция-источник
 * @param criteria функция-геттер значения для сортировки
 * @param order коллекция, в соответствии с которой нужно отсортировать
 */
export function resortBy<TItem, TCriteria>
(
  collection: Array<TItem>,
  criteria: (item: TItem) => TCriteria,
  order: Array<TCriteria>
): Array<TItem> {
  const map = new Map<TCriteria, TItem>();

  for (const item of collection) {
    map.set(criteria(item), item);
  }

  const result = [] as Array<TItem>;
  for (const crit of order) {
    const item = map.get(crit);

    if (item != null) {
      result.push(item);
    }
  }

  return result;
}
