/*
  Эта функция нужна для того, чтобы синхронизировать последние
  просмотренные квартиры приходящие с портала кукой, в момент перехода по страницам кабинета
*/

import latestFavoritesSync from '@/mixins/latestFavoritesSync';
import { defineNuxtMiddleware } from '@/types/nuxt2CompositionApiShims';

export default defineNuxtMiddleware((context) => {
  if (process.server) {
    return;
  }

  latestFavoritesSync(context);
});
