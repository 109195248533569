
import DOMPurify from 'dompurify';
export default {
  name: 'RemoteSVG',
  
  props: {
    url: {
      type: String,
      default: undefined,
      validator (value) {
        try {
          // eslint-disable-next-line no-new
          new URL(value);
          return true;
        } catch (error) {
          return false;
        }
      }
    },
    html: {
      type: String,
      default: undefined
    }
  },
  
  data () {
    return {
      svgContent: undefined
    };
  },
  
  async mounted () {
    if (this.html) {
      this.svgContent = this.sanitizeHTML(this.html);
    } else if (this.url) {
      try {
        // fetch по тому что, axios при отправке запроса подставляет content-type: "application/json",
        // это в свою очередь вызывает preflight запрос, и он падает с ошибкой CORS
        const response = await fetch(this.url);
        if (response.ok) {
          const content = await response.text();
          this.svgContent = this.sanitizeHTML(content);
        }
      } catch (error) {
        console.error('🚀 ~ file: RemoteSVG.vue ~ e', error);
      }
    }
  },
  
  methods: {
    sanitizeHTML (html) {
      return DOMPurify.sanitize(html, { USE_PROFILES: { svg: true } });
    }
  }
};
