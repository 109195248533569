export enum EnvironmentType {
  Dev = 'development',
  Stage = 'pre-production',
  Prod = 'production',
  Local = 'local',
}

export enum DeploymentMode {
  Development = 'development',
  Production = 'production'
}
