
import { PortalTarget } from 'portal-vue';
import { toRefs } from 'vue';
import { useScreenPlugin } from '@/app/plugins/vueScreen';

export default {
  name: 'CustomTemplateWithLeftBlock',

  components: {
    PortalTarget
  },

  props: {
    asideWidth: {
      type: String,
      required: true
    },

    isHeaderStatic: {
      type: Boolean,
      default: false
    },

    maxContentWidth: {
      type: String,
      default: undefined
    }
  },

  setup () {
    const {
      isTabletOrDesktop
    } = toRefs(useScreenPlugin());

    return {
      isTabletOrDesktop
    };
  }
};
