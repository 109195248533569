
import {
  SButton,
  STooltip,
  SIcon
} from '@strana-artw/ui-kit';
import { PhoneOutput } from '@strana-artw/cabinets-common';
import { toRefs } from 'vue';
import { useScreenPlugin } from '@/app/plugins/vueScreen';

export default {
  name: 'HeaderPhoneNumber',

  components: {
    SButton,
    PhoneOutput,
    STooltip,
    SIcon
  },

  props: {
    phone: {
      type: String,
      default: undefined
    }
  },

  setup () {
    const {
      isMobile,
      isTablet
    } = toRefs(useScreenPlugin());

    return {
      isMobile,
      isTablet
    };
  },

  computed: {
    isLoggedIn () {
      return this.$auth.loggedIn;
    }
  },

  methods: {
    modalOpenHandler () {
      this.$emit('modal-open');
    }
  }
};
