// Utilities
import * as easingPatterns from '~goTo/easing-patterns';
import {
    getContainer,
    getOffset,
} from '~goTo/utils';

/* eslint-disable no-promise-executor-return */

export function goTo(
    _target,
    _settings = {},
) {
    const settings = {
        container: document.scrollingElement || document.body || document.documentElement,
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic',
        appOffset: true,
        ..._settings,
    };
    const container = getContainer(settings.container);

    /* istanbul ignore else */
    if (settings.appOffset) {
        // && goTo.framework.application
        // const isDrawer = container.classList.contains('v-navigation-drawer');
        // const isClipped = container.classList.contains('v-navigation-drawer--clipped');
        // const { bar, top } = goTo.framework.application;
        const appBar = document.querySelector('#header');
        if (appBar) {
            settings.offset += appBar.getBoundingClientRect().height;
        }

        /* istanbul ignore else */
        // if (!isDrawer || isClipped) {
        //     settings.offset += top;
        // }
    }

    const startTime = performance.now();

    let targetLocation;
    if (typeof _target === 'number') {
        targetLocation = getOffset(_target) - settings.offset;
    } else {
        targetLocation = getOffset(_target) - getOffset(container) - settings.offset;
    }

    const startLocation = container.scrollTop;
    if (targetLocation === startLocation) {
        return Promise.resolve(targetLocation);
    }

    const ease = typeof settings.easing === 'function'
        ? settings.easing
        : easingPatterns[settings.easing];
    /* istanbul ignore else */
    if (!ease) {
        throw new TypeError(`Easing function "${settings.easing}" not found.`);
    }

    // Cannot be tested properly in jsdom
    /* istanbul ignore next */
    return new Promise(resolve => requestAnimationFrame(function step(currentTime) {
        const timeElapsed = currentTime - startTime;
        const progress = Math.abs(settings.duration ? Math.min(timeElapsed / settings.duration, 1) : 1);

        container.scrollTop = Math.floor(startLocation + (targetLocation - startLocation) * ease(progress));

        const clientHeight = container === document.body ? document.documentElement.clientHeight : container.clientHeight;
        if (progress === 1 || clientHeight + container.scrollTop === container.scrollHeight) {
            return resolve(targetLocation);
        }

        requestAnimationFrame(step);
    }));
}
