
import { SButton } from '@strana-artw/ui-kit';
import { resolveURL } from '@/shared/lib/utils/resolveURL';
// FIXME: для SButton должна быть возможность убирать background и hover. Для шрифта задавать размер, цвет и тд.
export default {
  name: 'AvatarButton',
  components: {
    SButton
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    notification: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isCurrentPage () {
      let route = this.link.path;
      let currentRoute = this.$route.path;

      if (route == null) {
        return false;
      }

      if (!currentRoute.endsWith('/')) {
        currentRoute += '/';
      }

      if (!route.endsWith('/')) {
        route += '/';
      }

      return currentRoute.endsWith(route);
    },

    attrs () {
      return {
        ...this.$attrs,
        ...this.item?.attrs
      };
    },

    link () {
      if (this.item.link) {
        return this.resolveURL(this.item.link);
      }

      return '';
    },

    listeners () {
      if (this.item.event) {
        const action = { click: () => this.$store.dispatch(this.item.event) };

        return {
          ...this.$listeners,
          ...action
        };
      }

      return this.$listeners;
    },

    classes () {
      return [
        'avatar-button-wrapper',

        { 'avatar-button-current-page': this.isCurrentPage }
      ];
    }
  },

  methods: {
    resolveURL
  }
};
