
import { SIcon } from '@strana-artw/ui-kit';
import { toRefs } from 'vue';
import { useScreenPlugin } from '@/app/plugins/vueScreen';

export default {
  name: 'CitySelect',

  components: {
    SIcon
  },

  props: {
    cities: {
      type: Array,
      default: () => []
    },

    isDropdown: {
      type: Boolean,
      default: false
    },

    value: {
      type: String,
      default: undefined
    }
  },

  setup () {
    const { isMobileOrTablet } = toRefs(useScreenPlugin());

    return {
      isMobileOrTablet
    };
  },

  emit: [
    'input',
    'select',
    'click',
    'update:is-dropdown'
  ],

  computed: {
    isDropdownShown () {
      return !this.isMobileOrTablet && this.isDropdown;
    },

    cityLabel () {
      return this.cities.find(({
        value
      }) => value === this.value)?.label;
    }
  },

  methods: {
    onSelect () {
      this.$emit('click');
      this.toggleListShown();
    },

    onCity (value) {
      this.closeList();
      this.$emit('input', value);
      this.$emit('select', value);
    },

    toggleListShown () {
      this.$emit('update:is-dropdown', !this.isDropdown);
    },

    closeList () {
      this.$emit('update:is-dropdown', false);
    }
  }
};
