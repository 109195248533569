
import { toRefs } from 'vue';
import HeaderNavigationInline from '@/components/layout/header/header-navigation/HeaderNavigationInline';
import { useScreenPlugin } from '@/app/plugins/vueScreen';

export default {
  components: {
    HeaderNavigationInline
  },

  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  setup () {
    const { isMobileOrTablet } = toRefs(useScreenPlugin());

    return {
      isMobileOrTablet
    };
  },

  computed: {
    filteredItems () {
      return this.items.filter(({
        hideDesktop
      }) => !hideDesktop);
    }
  }
};
