import { LocalScheme } from '@nuxtjs/auth-next/dist/runtime';
import { DataLayer } from '@/logic/DataLayer';

export default class PhoneScheme extends LocalScheme {
  async login (endpoint, {
    reset = true
  } = {}) {
    const response = await super.login(endpoint, {
      reset
    });
    const {
      data
    } = response;

    const dataLayer = new DataLayer();
    const strategy = this.$auth.$state.strategy;

    const roleSEO = {
      agents: 'agent',
      represes: 'agency',
      admins: 'admin',
      users: 'user'
    };

    dataLayer.setUserId(data.id);
    dataLayer.addEvent('login', {
      role: roleSEO[strategy]
    });

    return response;
  }

  async logout (endpoint) {
    const response = await super.logout(endpoint);
    const {
      history,
      location
    } = window;
    history.replaceState({}, document.title, location.pathname);

    if (process.env.DEVELOPMENT) {
      this.$auth.$storage.removeCookie('_lksession', {
        prefix: '',
        path: '/',
        domain: ''
      });
    }

    return response;
  }
}
