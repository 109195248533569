import { defineStore } from 'pinia';

interface PaymentTemplate {
  title: string;
  notifyText: string;
  buttonText: string;
}

interface State {
  paymentTemplate: Partial<PaymentTemplate>;
}

const endpoints = {
  getPaymentTemplate: (slug: string): string => `/api/templates/payment/${ slug }`
};

export const useTemplatesStore = defineStore('templates', {
  state: (): State => ({
    paymentTemplate: {}
  }),

  getters: {},

  actions: {
    async getPaymentTemplate (slug: string): Promise<void> {
      try {
        const url = endpoints.getPaymentTemplate(slug);
        const {
          data
        } = await this.$axios.get<PaymentTemplate>(url);

        this.paymentTemplate = data;
        // eslint-disable-next-line
      } catch (error: any) {
        this.$sentry.captureException(error);
        const message = error?.response?.data?.message ||
        error?.message ||
        'Произошла ошибка';
        console.error('🚀 ~ file: templates.ts ~ getPaymentTemplate ~ error', error);
        throw (message);
      }
    }
  }
});
