import { defineStore } from 'pinia';
import type { Route } from 'vue-router';
import { useBookingStore } from '@/store/booking';
import type {
  BookingErrorType
} from '@/types/Booking';
import type {
  Task,
  GroupStatus
} from '@/types/TaskChain';
import { BOOKING_ERROR_CODES } from '@/constants/errorCodes';
import { resolveTaskLink } from '@/shared/lib/composables/useTaskChain';
import type {
  PathType
} from '@/shared/types/openapi/';

interface State {
  taskInfo: Task | null;
  stepStatuses: Array<GroupStatus>;
}

export const useTaskChainStore = defineStore('taskChain', {
  state: (): State => ({
    taskInfo: null,
    stepStatuses: []
  }),

  getters: {},

  actions: {
    async getTaskStatus (taskId: number): Promise<void> {
      try {
        const url = `/api/task_management/task_instance/${ taskId }`;
        const { data } = await this.$axios.get<Task>(url);
        this.taskInfo = data;
      } catch (error) {
        console.error('🚀 ~ file: taskChain.ts ~ getTaskStatus ~ e', error);
        this.$sentry.captureException(error);
        useBookingStore().handleBookingError(error as BookingErrorType);
      }
    },

    async setTaskStatus (payload: { taskId: number; slug?: string | null }): Promise<void> {
      if (!payload.slug) {
        return;
      }

      try {
        const url = `/api/task_management/task_instance/${ payload.taskId }`;
        const params = {
          comment: '',
          task_amocrmid: '',
          slug: payload.slug
        };
        const { data } = await this.$axios.patch<Task>(url, params);
        this.taskInfo = data;
      } catch (error) {
        console.error('🚀 ~ file: taskChain.ts ~ setTaskStatus ~ e', error);
        this.$sentry.captureException(error);
        useBookingStore().handleBookingError(error as BookingErrorType);
      }
    },

    async getStepStatuses (taskId: number): Promise<void> {
      try {
        const url = '/api/task_management/task_chain/statuses';
        const { data } = await this.$axios.get<Array<GroupStatus>>(url, {
          params: {
            taskId
          }
        });
        this.stepStatuses = data;
      } catch (error) {
        console.error('🚀 ~ file: taskChain.ts ~ getStepStatuses ~ error', error);
        this.$sentry.captureException(error);
        useBookingStore().handleBookingError(error as BookingErrorType);
      }
    },

    async initTaskChainStep (route: Route): Promise<void> {
      const {
        bookingId,
        taskId
      } = route.query;

      if (!bookingId || !taskId) {
        useBookingStore().handleBookingError({
          reason: BOOKING_ERROR_CODES.NOT_FOUND
        });

        return;
      }

      await useBookingStore().getBooking(Number(bookingId));
      await this.getTaskStatus(Number(taskId));

      const currentPathName = this.taskInfo?.buttons[0]?.action?.type;

      const isMatched = route.matched.some(({ regex }) => {
        return currentPathName?.match(regex);
      });

      if (!isMatched) {
        const link = resolveTaskLink(currentPathName, route.query);

        if (link) {
          await this.$router.replace(link);
        }
      }
    },

    async getStatusesInDetail (bookingId: number): Promise<number | undefined> {
      try {
        const url = '/api/task_management/task_chain/statuses_in_detail';
        const { data } = await this.$axios.get<PathType<'/task_management/task_chain/statuses_in_detail', 'get', 200>>(url, {
          params: {
            bookingId
          }
        });

        // TODO Не правильные типы на бэке. Убрать as Array<GroupStatus> когда поправят
        this.stepStatuses = data?.groupStatuses as Array<GroupStatus>;

        return data?.taskId;
      } catch (error) {
        console.error('🚀 ~ file: taskChain.ts ~ getStatusesInDetail ~ error', error);
        this.$sentry.captureException(error);
      }
    }
  }
});
