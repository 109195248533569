import { defineStore } from 'pinia';

interface NotificationItem {
  exists: boolean;
  label: string;
  value: boolean;
}

interface NotificationResponse {
  'is_new': Array<NotificationItem>;
}

interface State {
  hasNewNotifications: boolean;
}

export const useNotificationStore = defineStore('notification', {
  state: (): State => ({
    hasNewNotifications: false
  }),

  getters: {},

  actions: {
    async checkNotifications (): Promise<void> {
      try {
        const { data } = await this.$axios.get<NotificationResponse>('api/notifications/clients/specs');

        if (data?.is_new?.length) {
          this.hasNewNotifications = Boolean(data.is_new.find(({ value }) => value)?.exists);
        }
      } catch (error) {
        console.log('🚀 ~ file: notification.ts ~ checkNotifications ~ error', error);
        this.$sentry.captureException(error);
      }
    }
  }
});
