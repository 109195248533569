
import { SImageLazy } from '@strana-artw/ui-kit';
export default {
  name: 'HeaderProfileMenuItem',

  components: {
    SImageLazy
  },

  props: {
    icon: {
      type: [String, Object],
      default: undefined
    },

    label: {
      type: String,
      default: undefined
    },

    action: {
      type: Function,
      default: () => ({})
    }
  },

  computed: {
    isComponent () {
      return typeof this.icon !== 'string';
    }
  }
};
