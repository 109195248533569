import {
  defineNuxtPlugin,
  onGlobalSetup,
  useRouter
} from '@nuxtjs/composition-api';
import { Context } from '@nuxt/types';


let nuxtApp: Context | undefined;
let router: Context['$router'] | undefined;

export default defineNuxtPlugin((ctx, inject) => {
  nuxtApp = ctx as Context;
  onGlobalSetup(() => {
    router = useRouter();
  });
});

export const useSentry = (): Context['$sentry'] => {
  if (!nuxtApp?.$sentry) {
    throw new Error('Sentry instance is not defined :(');
  }

  return nuxtApp?.$sentry;
};

export const useCabinetsApi = (): Context['$axios'] => {
  if (!nuxtApp?.$axios) {
    throw new Error('Cabinet API instance is not defined :(');
  }

  return nuxtApp?.$axios;
};

export const useIsomorphicRouter = (): Context['$router'] => {
  if (!router) {
    throw new Error('App router was undefined (perhaps called before app-context.ts plugin and onGlobalSetup executed)');
  }

  return router;
};
