
import {
  mapState,
  mapActions
} from 'pinia';
import {
  NameOutput
} from '@strana-artw/cabinets-common';
import { SIcon } from '@strana-artw/ui-kit';
import RemoteSVG from '@@/common/components/icons/RemoteSVG.vue';
import {
  getMeFromStore
} from '@@/common/services/user';
import HeaderNotificationIcon from '@/components/common/HeaderNotificationIcon.vue';
import HeaderSelectCity from '@/components/common/header-select-city';
import AvatarButton from '@/components/common/navigation/AvatarButton';
import TheMenuButton from '@/components/layout/TheMenuButton';
import { useDashboardSpecsStore } from '@/store/dashboard/specs';
import {
  useCitiesStore
} from '@/store/cities';
import { useAuthStore } from '@/store/authentication';
import { useHeaderStore } from '@/store/header';
import { useMenuStore } from '@/store/menu';
import VNavigationDrawer from '@/components/ui/VNavigationDrawer.vue';

export default {
  name: 'TheBurgerMenu',

  components: {
    RemoteSVG,
    HeaderNotificationIcon,
    HeaderSelectCity,
    AvatarButton,
    TheMenuButton,
    VNavigationDrawer,
    SIcon,
    NameOutput
  },

  computed: {
    ...mapState(useDashboardSpecsStore, [
      'specs'
    ]),

    ...mapState(useMenuStore, [
      'menu'
    ]),

    ...mapState(useHeaderStore, [
      'isOpenedMenu'
    ]),

    ...mapState(useCitiesStore, [
      'cities',
      'currentCity'
    ]),

    city: {
      get () {
        return this.currentCity?.value;
      },

      set (value) {
        this.setCurrentCity(value);
      }
    },

    profileName () {
      if (this.loggedIn) {
        const {
          name,
          surname,
          patronymic
        } = this.getMeFromStore();

        return {
          surname,
          name,
          patronymic
        };
      }

      return {};
    },

    notificationsCount () {
      const {
        notifications
      } = this.specs ?? {};

      return notifications;
    },

    loggedIn () {
      return this.$auth.loggedIn;
    }
  },

  watch: {
    $route: {
      handler () {
        if (process.client) {
          this.close();
        }
      },

      immediate: true
    }
  },

  methods: {
    ...mapActions(useCitiesStore, [
      'getCities',
      'getCurrentCity',
      'setCurrentCity'
    ]),

    ...mapActions(useHeaderStore, [
      'setHeaderMenuState'
    ]),

    ...mapActions(useAuthStore, [
      'logout'
    ]),

    getMeFromStore,

    close () {
      this.setHeaderMenuState(false);
      this.$emit('close');
    },

    profile () {
      this.$router.push({
        name: 'index-account'
      });
    },

    async logoutHandler () {
      this.setHeaderMenuState(false);
      await this.logout();
    }
  }
};
