
import {
  SButton,
  SModal
} from '@strana-artw/ui-kit';

export default {
  name: 'CallbackModalSuccess',

  components: {
    SButton,
    SModal
  },

  model: {
    prop: 'open',
    event: 'update:open'
  },

  props: {
    open: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    cancel () {
      this.closeModal();
    },

    closeModal () {
      this.$emit('update:open', false);
    }
  }
};
