
import { SIcon } from '@strana-artw/ui-kit';
export default {
  name: 'TheMenuButton',

  components: {
    SIcon
  },

  props: {
    icon: {
      type: String,
      required: true
    },

    light: {
      type: Boolean,
      default: false
    },

    outlined: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleClick () {
      this.$emit('click');
    }
  }
};
