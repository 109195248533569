// TODO: Остались только зависимости в VDatePickerHeader.vue и VDatePicker.vue

export function capitalizeFirstLetter (string: string): string {
  if (!string?.length) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function initial (string: string): string {
  if (!string?.length) {
    return '';
  }

  return string.charAt(0).toUpperCase();
}

export function replaceVariablesInTemplate (str: string, params: Record<string, unknown>): string {
  return str.replace(/\{(\d+)\}/g, (match, index) => String(params[Number(index)]));
}
