// noinspection ES6PreferShortImport
import {
  EnvironmentType,
  DeploymentMode
} from '../../shared/lib/utils/environment';

export const NODE_ENV = process.env.NODE_ENV;

export const ENVIRONMENT = process.env.ENVIRONMENT;
export const IS_LOCAL_MODE: boolean = ENVIRONMENT === EnvironmentType.Local;

export const IS_DEV_MODE:boolean = NODE_ENV === DeploymentMode.Development;

const CI_INFO = {
  NODE_ENV: process.env.NODE_ENV,
  ENVIRONMENT: process.env.ENVIRONMENT,
  SENTRY_DSN: process.env.SENTRY_DSN
};

const SERVER_API_URL = 'http://cabinet:1800';
const CLIENT_API_URL = `https://${ process.env.LK_SITE_HOST }`;
const HOST = process.env.HOST;
const DEVELOPMENT_HOST = process.env.DEVELOPMENT_HOST;
const PORTAL_PORT = process.env.PORTAL_PORT;
const CLIENT_CABINET_HOST = process.env.CLIENT_CABINET_HOST;
const SITE_HOST = HOST;
const MAIN_SITE_HOST = process.env.MAIN_SITE_HOST;
const PROTOCOL = process.env.PROTOCOL ?? 'https://';
const TOP_HOST = IS_LOCAL_MODE ? 'localhost' : process.env.TOP_HOST;

const getHostUrl = (host: string, _protocol?: string):string => {
  host = host.split(' ')[0] || '';

  return host
    ? (_protocol || PROTOCOL) + host
    : '';
};

const SERVICES_SITE_HOST = process.env.SERVICES_SITE_HOST || 'services.strana.com';
const SERVICES_SITE_URL = getHostUrl(SERVICES_SITE_HOST);
const SERVICES_SERVER_API_URL = process.env.SERVICES_SERVER_API_URL || SERVICES_SITE_URL;

export const ENV_VARIABLES = {
  ENVIRONMENT,
  NODE_ENV,
  IS_DEV_MODE,
  IS_LOCAL_MODE,
  CI_INFO,
  SERVER_API_URL,
  CLIENT_API_URL,
  HOST,
  DEVELOPMENT_HOST,
  PORTAL_PORT,
  CLIENT_CABINET_HOST,
  SITE_HOST,
  MAIN_SITE_HOST,
  PROTOCOL,
  TOP_HOST,
  SERVICES_SITE_HOST,
  SERVICES_SITE_URL,
  SERVICES_SERVER_API_URL
};
