// Миксин реализует логику загрузки просмотренных квартир из куки на бек

import { Context } from '@nuxt/types';
import {
  getCookieFromString,
  removeCookie
} from '@/shared/lib/utils/helpers';
import {
  useFavoritesStore
} from '@/store/favorites';
import { globalToProfitbase } from '@/shared/lib/utils/misc';

export default async (context: Context): Promise<void> => {
  async function action (): Promise<void> {
    if (!context.$auth.$state.loggedIn) {
      return;
    }
    const cookieName = 'favourites_latest';

    const cookieValue = getCookieFromString(document.cookie, cookieName, false);

    if (!cookieValue) {
      return;
    }

    const decodedCookieValue = decodeURIComponent(cookieValue);
    const latestFavoritesIds = JSON.parse(decodedCookieValue);

    if (!Array.isArray(latestFavoritesIds)) {
      removeCookie(cookieName);

      return;
    }

    const {
      setLatestFavorites
    } = useFavoritesStore(context.$pinia);

    const result = await setLatestFavorites?.(latestFavoritesIds.map((globalId) => globalToProfitbase(globalId)));

    if (result?.[0]?.clientId) {
      removeCookie(cookieName);
    }
  }

  if (context.$auth) {
    action();
  } else if (process.client) {
    window.onNuxtReady(() => action());
  }
};
