import { SMortgageAxiosConfig } from '@strana-artw/mortgage';
import qs from 'qs';

import type {
  Context
} from '@nuxt/types';
import type {
  Inject
} from '@nuxt/types/app';
import type {
  NuxtAxiosInstance
} from '@nuxtjs/axios';

declare module '@nuxt/types' {
  interface Context {
    $servicesApi?: NuxtAxiosInstance;
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $servicesApi?: NuxtAxiosInstance;
  }
}

let servicesApiInstance: NuxtAxiosInstance | undefined;


type Plugin = (ctx: Context, inject: Inject) => Promise<void> | void

const servicesApiPlugin: Plugin = (ctx, inject) => {
  const {
    isDev,
    $axios,
    $config
  } = ctx;

  let API_HOST = `https://${ $config.SERVICES_SITE_HOST.split(' ')[0] }`;
  let API_PATH = '/api/v1';

  if (isDev && process.client) {
    API_PATH = `/services${ API_PATH }`;
    API_HOST = location.origin;
  }

  const apiInstance = $axios.create({
    ...SMortgageAxiosConfig,
    baseURL: API_HOST + API_PATH,

    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
  });

  inject('servicesApi', apiInstance);
  ctx.$servicesApi = apiInstance;
};

export default servicesApiPlugin;

export const useServicesApi = ():NuxtAxiosInstance | undefined => servicesApiInstance;
