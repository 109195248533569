
import {
  SButton
} from '@strana-artw/ui-kit';
import MobileModal from '@/components/common/modals/mobile-modal';
import SelectCityList from '@/components/common/header-select-city/SelectCityList';

export default {
  name: 'CityModal',

  components: {
    MobileModal,
    SelectCityList,
    SButton
  },

  emit: [
    'input',
    'select',
    'update:is-show'
  ],

  props: {
    value: {
      type: String,
      default: undefined
    },

    cities: {
      type: Array,
      default: () => []
    },

    isShow: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    _value: {
      get () {
        return this.value;
      },

      set (value) {
        this.$emit('input', value);
      }
    },

    isButtonDisabled () {
      return !this.value;
    }
  },

  methods: {
    onClose () {
      this.$emit('update:is-show', false);
    },

    onSelect () {
      this.$emit('select', this.value);
    }
  }
};
