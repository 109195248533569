import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import {
  defineNuxtPlugin as defineNuxtPluginSource,
  defineNuxtMiddleware as defineNuxtMiddlewareSource
} from '@nuxtjs/composition-api';

// Для обхода несоответствий типов между Context в @nuxtjs/compositionApi и @nuxt/types
type Plugin = (context: Context, inject: Inject) => void
export const defineNuxtPlugin = defineNuxtPluginSource as unknown as (plugin: Plugin) => ReturnType<typeof defineNuxtPluginSource>;

type Middleware = (ctx: Context, cb: () => void) => Promise<void> | void
export const defineNuxtMiddleware = defineNuxtMiddlewareSource as unknown as (middleware: Middleware) => ReturnType<typeof defineNuxtMiddlewareSource>;
