import { defineStore } from 'pinia';
import { useCitiesStore } from '@/store/cities';

interface Appeal {
  name: string;
  phone: string;
  type: string;
  city?: string;
  email?: string;
  description?: string;
  'card_title'?: string;
}

interface State {
  appeal?: Appeal;
}

export const useAppealStore = defineStore('appeal', {
  state: (): State => ({
    appeal: undefined
  }),

  getters: {},

  actions: {
    async sendAppeal (): Promise<void> {
      const citiesStore = useCitiesStore();
      const city = citiesStore.currentCity?.value ?? undefined;
      const currentUrl = window.location.href;

      if (this.appeal && city) {
        try {
          await this.$axios.$post('api/users/ticket', {
            type: this.appeal.type,
            name: this.appeal.name,
            phone: this.appeal.phone,
            city,
            card_title: this.appeal.card_title
          }, {
            headers: { 'Applied-from-url': currentUrl }
          });
        } catch (error) {
          this.$sentry.captureException(error);
          console.log('🚀 ~ file: appeal.ts ~ sendAppeal ~ error', error);
          throw error;
        }
      }
    },

    setAppeal (appeal: Appeal): void {
      this.appeal = appeal;
    },

    resetAppeal (): void {
      this.appeal = undefined;
    }
  }
});
