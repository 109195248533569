
import ProfileOutput from '@/components/common/header-profile/ProfileOutput';
import HeaderProfileMenu from '@/components/common/header-profile/HeaderProfileMenu';

export default {
  name: 'HeaderProfile',

  components: {
    HeaderProfileMenu,
    ProfileOutput
  },

  props: {
    // Возможность позиционировать меню от края карточки
    menuPosition: {
      type: String,
      default: 'right',
      validator (value) {
        return [
          'left',
          'right'
        ].includes(value);
      }
    }
  },

  emits: ['login'],

  data () {
    return {
      // Сделано для отрисовки скелета
      isLoading: true,
      showDropdown: false
    };
  },

  computed: {
    // Сделано для отсутвия регистрации
    // isLoggedIn () {
    //   return this.$auth.loggedIn;
    // },

    classes () {
      return {
        [this.$style[`m-${ this.menuPosition }`]]: true
      };
    }
  },

  methods: {
    toggleDropdown () {
      this.showDropdown = !this.showDropdown;
    },

    closeDropdown () {
      this.showDropdown = false;
    }
  }
};
