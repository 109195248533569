
import {
  mapState,
  mapActions
} from 'pinia';
import {
  SButton,
  SInput,
  SModal
} from '@strana-artw/ui-kit';
import {
  validationMixin
} from 'vuelidate';
import {
  required,
  maxLength,
  minLength
} from 'vuelidate/lib/validators';
import {
  getMeFromStore
} from '@@/common/services/user';
import { PolicyOutput } from '@strana-artw/cabinets-common';
import {
  prettyPhone
} from '@/shared/lib/utils/helpers';
import {
  nameValidator,
  phoneValidator
} from '@/shared/lib/utils/validators';
import { useAppealStore } from '@/store/appeal';
import { ENV_VARIABLES } from '@/app/config/environment';

export default {
  name: 'CallbackModalForm',

  components: {
    PolicyOutput,
    SButton,
    SInput,
    SModal
  },

  mixins: [
    validationMixin
  ],

  model: {
    prop: 'open',
    event: 'update:open'
  },

  validations: {
    form: {
      fullName: {
        required,
        alpha: nameValidator,
        minLength: minLength(2),
        maxLength: maxLength(35),
        serverError () {
          return !this.hasServerError('fullName');
        }
      },

      phone: {
        isPhone: phoneValidator,
        required,
        minLength
      }
    }
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      required: true,
      validator (value) {
        return [
          'callback',
          'parking'
        ].includes(value);
      }
    },

    title: {
      type: String,
      required: true
    },

    cardTitle: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      form: {
        fullName: null,
        phone: null
      },

      host: ENV_VARIABLES.HOST,
      isLoading: false,
      serverErrors: {}
    };
  },

  computed: {
    ...mapState(useAppealStore, [
      'appeal'
    ]),

    fullNameError () {
      if (!this.$v.form.fullName.$dirty) {
        return undefined;
      }
      const {
        required,
        minLength,
        maxLength,
        alpha,
        serverError
      } = this.$v.form.fullName;

      if (!required) {
        return 'Введите имя';
      }

      if (!minLength) {
        return 'Слишком короткое имя';
      }

      if (!maxLength) {
        return 'Слишком длинное имя';
      }

      if (!alpha) {
        return 'Введите корректное имя';
      }

      if (!serverError) {
        return this.serverErrors.fullName || 'Ошибка на сервере';
      }

      return '';
    },

    phoneError () {
      if (!this.$v.form.phone.$dirty) {
        return undefined;
      }
      const {
        required,
        minLength,
        isPhone
      } = this.$v.form.phone;

      if (!required) {
        return 'Введите телефон';
      }

      if (!minLength) {
        return 'Слишком короткий телефон';
      }

      if (!isPhone) {
        return 'Введите корректный номер телефона';
      }

      return '';
    }
  },

  watch: {
    open (value) {
      if (!value) {
        return;
      }

      const {
        name,
        surname,
        phone
      } = this.getMeFromStore();

      this.form.fullName = [name, surname].filter(Boolean).join(' ');
      this.form.phone = this.prettyPhone(phone);
    }
  },

  methods: {
    ...mapActions(useAppealStore, [
      'sendAppeal',
      'resetAppeal',
      'setAppeal'
    ]),

    getMeFromStore,

    prettyPhone,

    hasServerError (field) {
      return Boolean(this.serverErrors[field]);
    },

    handlePhoneInput (value) {
      this.form.phone = value.replace('+7 8', '+7 (');
    },

    cancel () {
      this.closeModal();
    },

    async confirm () {
      this.setAppeal({
        name: this.form.fullName,
        phone: this.form.phone,
        type: this.type,
        card_title: this.cardTitle
      });

      if (this.appeal) {
        try {
          this.isLoading = true;
          await this.sendAppeal();
          this.$emit('confirm');
          this.resetAppeal();
          this.closeModal();
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }
    },

    resetForm () {
      this.$v.$reset();
      this.form = {
        fullName: null,
        phone: null
      };
    },

    closeModal () {
      this.resetForm();
      this.$emit('update:open', false);
    }
  }
};
