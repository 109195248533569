
import { SIcon } from '@strana-artw/ui-kit';
import HeaderCountIcon from '@/components/common/HeaderCountIcon';

export default {
  name: 'HeaderFavoritesIcon',

  components: {
    HeaderCountIcon,
    SIcon
  },

  props: {
    count: {
      type: Number,
      default: 0
    }
  }
};
