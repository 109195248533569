import { defineStore } from 'pinia';
import type { StateChanger } from 'vue-infinite-loading';
import { PageInfo } from '@/types/PageInfo';

interface Image {
  src: string;
  s3: string;
  aws: string;
}

interface Steps {
  id: number;
  description: string;
}

interface Condition {
  id: number;
  title: string;
  steps: Array<Steps>;
}

interface ServiceItem {
  id: number;
  title: string;
  hint: string;
  imagePreview: Image;
  imageIcon: Image;
}

interface CatalogItem {
  id: number;
  title: string;
  results: Array<ServiceItem>;
}

interface Service {
  id: number;
  title: string;
  announcement: string;
  description: string;
  hint: string;
  imageDetailed: Image;
  condition: Condition;
}

interface Category {
  id: number | null;
  title: string;
}

interface getServicesQueryParams {
  categoryId?: number;
}

interface getTicketsQueryParams {
  categoryId?: number;
  page?: number;
}

interface FormData {
  fullName: string;
  phone: string;
  serviceId: number;
}

interface TicketStatus {
  name: string;
}

interface TicketServiceCategory {
  title: string;
}

interface TicketService {
  title: string;
  category: TicketServiceCategory;
}

interface Ticket {
  id: number;
  bookingId: number | null;
  status: TicketStatus;
  service: TicketService;
}

interface TicketEmptyTemplate {
  buttonText: string;
  description: string;
  title: string;
}

interface GroupStatus {
  name: string;
  sort: number;
}

interface State {
  services: Array<CatalogItem>;
  service: Service | undefined;
  categories: Array<Category>;
  facets: Array<Category>;
  pageInfo: PageInfo;
  tickets: Array<Ticket>;
  ticketEmptyTemplate: TicketEmptyTemplate | undefined;
  statuses: Array<GroupStatus>;
}

export const useServicesStore = defineStore('services', {
  state: (): State => ({
    services: [],
    service: undefined,
    categories: [],
    facets: [],
    pageInfo: {},
    tickets: [],
    ticketEmptyTemplate: undefined,
    statuses: []
  }),

  getters: {},

  actions: {
    async getCategories (): Promise<void> {
      try {
        const { data } = await this.$axios.get('api/add-services/specs');
        this.categories = data.categories;
      } catch (error) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: services.ts ~ getCategories ~ error', error);
      }
    },

    async getFacets (): Promise<void> {
      try {
        const { data } = await this.$axios.get('api/add-services/facets');
        this.facets = data.categories;
      } catch (error) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: services.ts ~ getFacets ~ error', error);
      }
    },

    async getServices (id: number | null): Promise<void> {
      try {
        const queryParams: getServicesQueryParams = {};

        if (id) {
          queryParams.categoryId = id;
        }

        const { data } = await this.$axios.get('api/add-services', {
          params: { ...queryParams }
        });
        this.services = data;
      } catch (error) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: services.ts ~ getServices ~ error', error);
      }
    },

    async getTickets (queryParams?: getTicketsQueryParams): Promise<void> {
      try {
        const {
          data: {
            result,
            pageInfo,
            template,
            statuses
          }
        } = await this.$axios.get('api/add-services/tickets', {
          params: { ...queryParams }
        });

        this.statuses = statuses;
        this.ticketEmptyTemplate = template;
        this.tickets = result;
        this.pageInfo = pageInfo;
      } catch (error) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: services.ts ~ getTickets ~ error', error);
      }
    },

    async getService (id: number): Promise<void> {
      try {
        const { data } = await this.$axios.get<Service>(`api/add-services/${ id }`);
        this.service = data;
      } catch (error) {
        this.service = undefined;
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: services.ts ~ getService ~ error', error);
      }
    },

    cleanService (): void {
      this.service = undefined;
    },

    async sendServiceForm (formData: FormData): Promise<void> {
      try {
        await this.$axios.post('api/add-services', formData);
      } catch (error) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: services.ts ~ sendServiceForm ~ error', error);
      }
    },

    async nextPage ({ page, infiniteState }: {page: PageInfo; infiniteState: StateChanger}): Promise<void> {
      if (!page.next_page) {
        return;
      }

      try {
        const {
          data: {
            result,
            pageInfo
          }
        } = await this.$axios.get(page.next_page);

        if (!pageInfo) {
          throw new Error('page info not received');
        }

        if (result?.length) {
          this.pageInfo = pageInfo;
          this.tickets = [...this.tickets, ...result];

          if (!pageInfo.next_page) {
            infiniteState.complete();
          } else {
            infiniteState.loaded();
          }
        } else {
          infiniteState.loaded();
        }
      } catch (error) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: services.ts ~ ~ nextPage ~ error', error);
        infiniteState.complete();
      }
    }
  }
});
