
import { SIcon } from '@strana-artw/ui-kit';

export default {
  name: 'SelectCityList',

  components: {
    SIcon
  },

  props: {
    cities: {
      type: Array,
      default: () => []
    },

    value: {
      type: String,
      default: undefined
    }
  },

  methods: {
    onItem (value) {
      this.$emit('input', value);
    }
  }
};
