import { defineStore } from 'pinia';
import { AWSImage } from '@/types/AWSImage';

interface ImagesItems {
  'new_pwd_image': AWSImage;
  'login_pwd_image': AWSImage;
  'forgot_pwd_image': AWSImage;
  'forgot_send_image': AWSImage;
  'login_email_image': AWSImage;
  'enter_agency_image': AWSImage;
  'confirm_send_image': AWSImage;
  'enter_personal_image': AWSImage;
  'enter_password_image': AWSImage;
  'accept_contract_image': AWSImage;
  'confirmed_email_image': AWSImage;
}

interface State {
  images: {
    items: Partial<ImagesItems>;
    isFetched: boolean;
  };
}

export const useImagesStore = defineStore('images', {
  state: (): State => ({
    images: {
      items: {},
      isFetched: false
    }
  }),

  getters: {
    getImage: (state) => Object.entries(state.images.items).reduce((out, [key, item]) => {
      out[key] = item.aws as unknown as AWSImage;

      return out;
    }, {} as Record<string, AWSImage>)
  },

  actions: {
    async getImages () {
      if (!this.images.isFetched) {
        try {
          const { data } = await this.$axios.get('/api/pages/broker_registration');
          this.images.items = data;
          this.images.isFetched = true;

          return true;
        } catch (error) {
          this.$sentry.captureException(error);

          return false;
        }
      } else {
        return true;
      }
    }
  }
});
