import Vue from 'vue';

export function isUnset (val: unknown): boolean {
  return typeof val === 'undefined' || val === null;
}

export function isSet (val: unknown): boolean {
  return !isUnset(val);
}

export function lockBody (): void {
  const htmlElement = document.documentElement;
  const bodyElement = document.body;

  if (htmlElement && bodyElement) {
    htmlElement.style.height = '100%';
    bodyElement.style.height = '100%';
    bodyElement.style.overflow = 'hidden';
  }
}

export function unlockBody (): void {
  const htmlElement = document.documentElement;
  const bodyElement = document.body;

  if (htmlElement && bodyElement) {
    htmlElement.style.height = '';
    bodyElement.style.height = '';
    bodyElement.style.overflow = '';
  }
}

export function cleanPhone (prettyPhoneNumber: string): string {
  return prettyPhoneNumber.replace(/[ \-()_]/g, '');
}

export function prettyPhone (rawPhoneNumber: string): string {
  return rawPhoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3-$4-$5');
}

export function getCookieFromString (cookie: string, name: string, decode: boolean = true): string | undefined {
  const decodedCookie = decode ? decodeURIComponent(cookie) : cookie;

  const ca = decodedCookie.trim().split(';');
  let value: string | undefined;
  ca.forEach((item) => {
    const param = item.split('=');

    if (param[0].trim() === name) {
      value = param[1];
    }
  });

  return value;
}

export function removeCookie (name: string): void {
  const isLocal: boolean = process.env.ENVIRONMENT === 'local';
  const host: string | undefined = isLocal ? process.env.DEVELOPMENT_HOST : process.env.HOST;

  document.cookie = `${ name }=""; path=/; domain=.${ host }; expires=Thu, 01 Jan 1970 00:00:00 GMT; max-age=0`;
}

export function convertToUnit (str: string, unit = 'px'): string | undefined {
  if (str === undefined || str === null || str === '') {
    return undefined;
  } else if (isNaN(Number(str))) {
    return String(str);
  } else {
    return `${ Number(str) }${ unit }`;
  }
}

export function createSimpleFunctional (
  c: string,
  el = 'div',
  name?: string
): typeof Vue {
  return Vue.extend({
    name: name || c.replace(/__/g, '-'),

    functional: true,

    render (h, { data, children }) {
      data.staticClass = `${ c } ${ data.staticClass || '' }`.trim();

      return h(el, data, children);
    }
  });
}

// KeyboardEvent.keyCode aliases
export const keyCodes = Object.freeze({
  enter: 13,
  tab: 9,
  delete: 46,
  esc: 27,
  space: 32,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  end: 35,
  home: 36,
  del: 46,
  backspace: 8,
  insert: 45,
  pageup: 33,
  pagedown: 34,
  shift: 16
});

const camelizeRE = /-(\w)/g;
export const camelize = (str: string): string => str.replace(camelizeRE, (_, c) => c ? c.toUpperCase() : '');

export function kebabCase (str: string): string {
  return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export function getSlot (vm: Vue, name = 'default', data: unknown, optional: boolean = false): unknown {
  const scopedSlot = vm.$scopedSlots[name];
  const defaultSlot = vm.$slots[name];

  if (scopedSlot) {
    const slotData = data instanceof Function ? data() : data;

    return scopedSlot(slotData);
  } else if (defaultSlot && (!data || optional)) {
    return defaultSlot;
  }

  return undefined;
}

export function wrapInArray (v = null): Array<unknown> {
  return v !== null ? Array.isArray(v) ? v : [v] : [];
}

export function getObjectValueByPath (obj: Record<string, unknown>, path: string, fallback: unknown): unknown {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== 'string') {
    return fallback;
  }

  if (obj[path] !== undefined) {
    return obj[path];
  }
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, ''); // strip a leading dot

  return getNestedValue(obj, path.split('.'), fallback);
}

export function getNestedValue (obj: Record<string, unknown>, path: Array<string>, fallback: unknown): unknown {
  const last = path.length - 1;

  if (last < 0) {
    return obj === undefined ? fallback : obj;
  }

  for (let i = 0; i < last; i++) {
    if (obj === null) {
      return fallback;
    }
    obj = obj[path[i]] as Record<string, unknown>;
  }

  if (obj === null || obj === undefined) {
    return fallback;
  }

  return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

export function keys (o: Record<string, unknown>): Array<string> {
  return Object.keys(o);
}

export function clamp (value: number, min = 0, max = 1): number {
  return Math.max(min, Math.min(max, value));
}

export function padEnd (str: string, length: number, char = '0'): string {
  return str + char.repeat(Math.max(0, length - str.length));
}

export function chunk (str: string, size = 1): Array<string> {
  const chunked = [];
  let index = 0;
  while (index < str.length) {
    chunked.push(str.substr(index, size));
    index += size;
  }

  return chunked;
}

interface IRemapInternalIcon { component: string; key: string; props: Record<string, unknown> }

export function remapInternalIcon (vm: Vue, iconName: string): IRemapInternalIcon {
  const component = 'svg-icon';
  let iconNameNormalized = iconName;

  if (iconName.startsWith('$')) {
    iconNameNormalized = kebabCase(iconName.split('$').pop() as string);
  }

  return {
    component,
    key: iconNameNormalized,
    props: {
      name: iconNameNormalized
    }
  };
}

export function createRange (length: number): Array<number> {
  return Array.from({ length }, (v, k) => k);
}
