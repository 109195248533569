import { defineNuxtMiddleware } from '@/types/nuxt2CompositionApiShims';

export default defineNuxtMiddleware(async ({
  route,
  $auth,
  redirect,
  $sentry
}): Promise<void> => {
  const token = route.query.token ?? $auth.strategy.token.get();

  if (!token) {
    redirect('/login');
  }

  try {
    await $auth.setUserToken(String(token));
  } catch (error) {
    $sentry.captureException(error);
    console.error('🚀 ~ file: tokenAuth.ts ~ error', error);
    redirect('/login');
  }
});
