import { defineStore } from 'pinia';

interface ProfileResponse {
  name: string;
  email: string;
  phone: string;
  surname: string;
  patronymic: string;
  // eslint-disable-next-line camelcase
  birth_date: string;
  // eslint-disable-next-line camelcase
  passport_series: string;
  // eslint-disable-next-line camelcase
  passport_number: string;
  type: string;
  // eslint-disable-next-line camelcase
  interested_sub: boolean;
}

interface Profile {
  name: string;
  email: string;
  phone: string;
  surname: string;
  patronymic: string;
  birthdate: string;
  passportSeries: string;
  passportNumber: string;
  type: string;
  subscriptions: {
    favorites: boolean;
  };
}

const snakeCaseKeysMap: Record<keyof Profile, string> = {
  name: 'name',
  email: 'email',
  phone: 'phone',
  surname: 'surname',
  patronymic: 'patronymic',
  birthdate: 'birth_date',
  passportSeries: 'passport_series',
  passportNumber: 'passport_number',
  type: 'type',
  subscriptions: 'interested_sub'
};

const camelCaseFormat = (
  data: ProfileResponse
): Profile => ({
  name: data.name,
  email: data.email,
  phone: data.phone,
  surname: data.surname,
  patronymic: data.patronymic,
  birthdate: data.birth_date,
  passportSeries: data.passport_series,
  passportNumber: data.passport_number,
  type: data.type,
  subscriptions: {
    favorites: data.interested_sub
  }
});

interface State {
 profile?: Profile;
}

export const useProfileStore = defineStore('profile', {
  state: (): State => ({
    profile: undefined
  }),

  getters: {},

  actions: {
    async getProfile (): Promise<void> {
      try {
        const data = await this.$axios.$get<ProfileResponse>('api/users/profile');

        this.profile = camelCaseFormat(data);
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: profile.ts ~ getProfile ~ e', error);
      }
    },

    async updateProfile (profile: Partial<Profile>): Promise<void> {
    /**
    * Данный кусок кода сделан для того, чтобы принимать обьект profile, который может прийти не весь, а Partial
    * и потом вернуть его ключи в snake_case для отправки по API
    */
      const profileInSnakeCase: Partial<Record<keyof ProfileResponse, unknown>> = {};
      for (const [key, value] of Object.entries(profile)) {
        const snakeCaseKey = snakeCaseKeysMap[key as keyof Profile] as keyof ProfileResponse;
        profileInSnakeCase[snakeCaseKey] = value;
      }

      if (profile.subscriptions) {
        profileInSnakeCase.interested_sub = profile.subscriptions.favorites;
      }

      try {
        const data = await this.$axios.$patch('api/users/partial_update', profileInSnakeCase);

        this.profile = camelCaseFormat(data);
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: profile.ts ~ updateProfile ~ e', error);
      }
    }
  }
});
