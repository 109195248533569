import { defineStore } from 'pinia';

import type {
  Booking,
  BookingHistoryResponse,
  PropertyType
} from '@/types/Booking';

interface BookingsResponse {
  count: number;
  result: Array<Booking>;
}

interface BookingSpecsResponse {
  propertyTypes: Array<PropertyType>;
}

interface State {
  bookings?: Array<Booking>;
  bookingSpecs?: Array<PropertyType>;
  bookingsWithHistory?: Array<Booking>;
  activeSpec?: PropertyType;
  activeBooking?: Booking;
  isShowPlan: boolean;
  bookingsLoadingState: boolean;
}

export const useBookingsStore = defineStore('bookings', {
  state: (): State => ({
    bookings: undefined,
    bookingSpecs: undefined,
    bookingsWithHistory: undefined,
    activeSpec: undefined,
    activeBooking: undefined,
    isShowPlan: false,
    bookingsLoadingState: false
  }),

  getters: {},

  actions: {
    async getBookingSpecs (): Promise<void> {
      try {
        const response = await this.$axios.get<BookingSpecsResponse>('api/booking/specs');
        this.bookingSpecs = response.data.propertyTypes;
      } catch (error) {
        console.log('🚀 ~ file: bookings.ts ~ getBookingSpecs ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async getBookings (propertyType?: string): Promise<void> {
      try {
        this.bookingsLoadingState = true;
        const {
          data: {
            result
          }
        }: {
          data: BookingsResponse;
        } = await this.$axios.get('api/booking', {
          params: {
            propertyType
          }
        });

        this.bookings = result;
      } catch (error) {
        console.log('🚀 ~ file: bookings.ts ~ getBookings ~ error', error);
        this.$sentry.captureException(error);
      } finally {
        this.bookingsLoadingState = false;
      }
    },

    async getBookingsWithHistory (): Promise<void> {
      try {
        this.bookingsWithHistory = (await this.$axios.get<BookingsResponse>('api/booking/booking_event_history/list')).data.result;
      } catch (error) {
        console.log('🚀 ~ file: bookings.ts ~ getBookings ~ error', error);
        this.$sentry.captureException(error);

        return;
      } finally {
        this.bookingsLoadingState = false;
      }
    },

    setActiveBooking (payload: Booking | undefined): void {
      this.activeBooking = payload;
    },

    async setActiveSpec (payload: PropertyType | undefined): Promise<void> {
      this.activeSpec = payload;
      this.setActiveBooking(undefined);
      await this.getBookings(this.activeSpec?.slug);
    },

    setShowPlan (payload: boolean): void {
      this.isShowPlan = payload;
    },

    getBookingStages (booking: Booking) {
      try {
        return this.$axios.get<BookingHistoryResponse>(`api/booking/booking_event_history/${ booking.id }`).then((response) => response.data);
      } catch (error) {
        console.log('🚀 ~ file: [store/bookings.ts] ~ getBookingStages ~ error', error);
        this.$sentry.captureException(error);

        return Promise.reject(error);
      }
    },

    getHistoryOfferContents (bookingId: number, stageId: number) {
      try {
        return this.$axios.get<{ signedOffer: string }>(`api/booking/documents/archive/${ bookingId }?booking_event_history_id=${ stageId }`).then((response) => response.data);
      } catch (error) {
        console.log('🚀 ~ file: [store/bookings.ts] ~ getHistoryOfferContents ~ error', error);
        this.$sentry.captureException(error);

        return Promise.reject(error);
      }
    }
  }
});
