import {
  HttpMethod,
  Response
} from './types';

export const responses: Array<Response> = [
  [HttpMethod.GET, '/foo', 200, { foo: 'bar' }],
  [HttpMethod.GET, '/bar', 200],
  [HttpMethod.GET, '/baz', 200]
];
