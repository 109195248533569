
export default {
  name: 'MobileModal',

  props: {
    title: {
      type: String,
      default: undefined
    },

    isShow: {
      type: Boolean,
      default: false
    }
  },

  emits: ['close'],

  computed: {
    classes () {
      return [
        {
          [this.$style.open]: this.isShow
        }
      ];
    }
  },

  methods: {
    closeModal () {
      this.$emit('close');
    }
  }
};
