export const BOOKING_ERROR_CODES = {
  OFFER_ERROR: 'booking_offer_error',
  PROPERTY_UNDEFINED: 'booking_property_undefined',
  PROPERTY_UNAVAILABLE: 'booking_property_unavailable',
  PROPERTY_MISSING: 'booking_property_missing',
  UNFINISHED_EXISTS: 'booking_unfinished_exists',
  GET_PERIODS_ERROR: 'booking_get_periods_error',
  SELECT_PERIODS_ERROR: 'booking_select_periods_error',
  NOT_FOUND: 'booking_not_found',
  TIME_OUT_REPEAT: 'booking_time_out_repeat',
  TIME_OUT: 'booking_time_out',
  PAYMENT_FAILED: 'booking_payment_failed',
  UNKNOWN_REASON: 'booking_unknown_reason'
};
