import type { RawLocation } from 'vue-router';
import { useIsomorphicRouter } from '@/app/plugins/app-context';

const getRelativePath = (url: string) :string => {
  const hostRegex = RegExp(`^\\w*://${ process.env.HOST }`);

  return url.replace(hostRegex, '');
};

const resolveURL = (url?: string): RawLocation | null => {
  if (typeof url === 'string') {
    const relativePath = getRelativePath(url);
    const router = useIsomorphicRouter();
    const resolved = router.resolve(relativePath);

    return resolved.route.matched.length
      ? resolved.location
      : {
        name: 'external-link-redirect',
        path: `/external-link-redirect/?to=${ url }`,
        query: {
          to: url
        }
      };
  }

  return null;
};

export {
  resolveURL
};
